
import { defineComponent } from 'vue'
import { BillDetailDto, BillDto } from '@/api/bill'
import { getPayrollList, payrollDelete } from '@/api/payroll'
import { downloadHistoryList } from '@/api/export'
import batchPayroll from '@/components/batch-payroll.vue'
import user from '../utils/user'
import { dateFormat, downLoadFile } from '../utils/util'
import axios from 'axios'
export default defineComponent({
    name: 'Payroll',
    components: {
        batchPayroll
    },
    data () {
        return {
            isBatchPay: false,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            tableData: [] as any,
            searchText: '',
            searchValue: '',
            detailInfo: {} as BillDto,
            selects: [] as string[],
            isAlert: true
        }
    },
    created () {
        // this.getPayrollList()
        this.getDownloadHistoryList()
    },
    methods: {
        async getDownloadHistoryList () {
            console.log('调用了吗')
            const result = await downloadHistoryList({
                pageNum: this.pageNum,
                pageSize: this.pageSize
            })
            this.total = result.total
            this.tableData = result.list
            result.list.map((item:any, index:number) => {
                this.tableData[index].createTime = dateFormat('YYYY-mm-dd HH:MM:SS', new Date(item.createTime))
            })
            console.log(result.list, '调用结果')
        },
        async getPayrollList () {
            const result = await getPayrollList({
                searchText: this.searchValue,
                pageSize: this.pageSize,
                pageNum: this.pageNum
            })
            this.total = result.total
            this.tableData = result.list
        },
        handleSizeChange (event: any) {
            console.log(event)
            this.pageNum = 1
            this.getDownloadHistoryList()
        },
        // 导出数据
        async onExport (data:any) {
            // window.location.href = data.fileUrl
            axios({
                url: data.fileUrl,
                method: 'GET',
                responseType: 'blob'
            }).then(async (response) => {
                await downLoadFile(response, data.exportType, data.updateTime, data.remark)
                await this.$message.success('导出成功！')
            })

            // this.$message.success('导出成功！')
        }
    }
})
