import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/close.png'


const _hoisted_1 = { class: "container block block-alone" }
const _hoisted_2 = {
  key: 0,
  class: "alert-box"
}
const _hoisted_3 = { class: "table" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_batchPayroll = _resolveComponent("batchPayroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "search" }, " 下载项目列表 ", -1)),
    (_ctx.isAlert)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "alert-describe" }, [
            _createTextVNode(" 亲爱的用户，为了方便您的工作，我们将在站内为您保存下载数据链接 "),
            _createElementVNode("text", { class: "alert-describe-blue" }, "48小时"),
            _createTextVNode("。 ")
          ], -1)),
          _createElementVNode("img", {
            src: _imports_0,
            class: "close-img point",
            alt: "",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isAlert = false))
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_tabs, { "model-value": "detail" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_table, {
            data: _ctx.tableData,
            style: {"width":"100%"},
            "header-row-style": { color: '#202536'},
            "header-cell-style": {background: '#F7F8FC'},
            "row-style": { color: '#5E617D' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "fileName",
                label: "下载内容",
                width: "510px"
              }),
              _createVNode(_component_el_table_column, {
                prop: "createTime",
                label: "生成时间"
              }),
              _createVNode(_component_el_table_column, { label: "生成状态" }, {
                default: _withCtx((scope) => [
                  _createElementVNode("p", {
                    style: _normalizeStyle({color: scope.row.exportStatus == 1 ? '#5E617D' : scope.row.exportStatus == 2 ? '#FF7E68' : '#00D679' }),
                    class: "point"
                  }, _toDisplayString(scope.row.exportStatus == 1 ? '已生成' : scope.row.exportStatus == 2 ? '生成失败' : '生成中'), 5)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, { label: "操作" }, {
                default: _withCtx((scope) => [
                  _createElementVNode("p", {
                    style: {color: '#1989FA' },
                    class: "point",
                    onClick: ($event: any) => (_ctx.onExport(scope.row))
                  }, " 下载 ", 8, _hoisted_4)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data"]),
          _createVNode(_component_el_pagination, {
            class: "pagination",
            currentPage: _ctx.pageNum,
            "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageNum) = $event)),
            "page-size": _ctx.pageSize,
            "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.pageSize) = $event)),
            "page-sizes": [10, 30, 50, 100],
            layout: "total, sizes, prev, pager, next, jumper",
            total: _ctx.total,
            onSizeChange: _ctx.handleSizeChange,
            onCurrentChange: _ctx.getDownloadHistoryList
          }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
        ])
      ]),
      _: 1
    }),
    (_ctx.isBatchPay)
      ? (_openBlock(), _createBlock(_component_batchPayroll, {
          key: 1,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isBatchPay = false)),
          onComplete: _ctx.getPayrollList,
          isType: "payroll"
        }, null, 8, ["onComplete"]))
      : _createCommentVNode("", true)
  ]))
}