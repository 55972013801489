import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "border-box" }
const _hoisted_2 = { class: "details" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex mg-t-12" }
const _hoisted_5 = { class: "flex mg-t-12 flex-ai-c" }
const _hoisted_6 = {
  key: 0,
  class: "flex shadow-box flex-ai-c"
}
const _hoisted_7 = { class: "fc-orange" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Warning = _resolveComponent("Warning")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_dialogRecharge = _resolveComponent("dialogRecharge")!
  const _component_dialogSeatDetails = _resolveComponent("dialogSeatDetails")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "bold border-bottom title-box" }, "当前订单", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "gc-grey pd-t-12" }, "公司：", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.orderInfo?.companyName), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "gc-grey" }, "当前团队账户到期时间：", -1)),
          _createElementVNode("div", null, _toDisplayString(_ctx.orderInfo?.accountExpireTime), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[8] || (_cache[8] = _createElementVNode("div", { class: "gc-grey" }, "当前已有席位数：", -1)),
          _createElementVNode("div", {
            class: "fc-primary pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isShowRecord = true))
          }, _toDisplayString(_ctx.orderInfo?.hasSeat) + "（已用" + _toDisplayString(_ctx.orderInfo?.useSeat) + "）", 1),
          (_ctx.orderInfo?.expireSeatsCount > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_el_icon, { class: "fc-orange mg-r-5" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Warning)
                  ]),
                  _: 1
                }),
                _cache[6] || (_cache[6] = _createTextVNode(" 检测到您有")),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.orderInfo?.expireSeatsCount) + "个", 1),
                _cache[7] || (_cache[7] = _createTextVNode("席位即将到期 "))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_el_button, {
          size: "mini",
          class: "mg-t-16 mg-b-20",
          type: "primary",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowRecharge=true))
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("续费")
          ])),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_dialogRecharge, {
      show: _ctx.isShowRecharge,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isShowRecharge) = $event)),
      "no-need-router": "",
      onRefresh: _ctx.refresh,
      onChangeTab: _ctx.handleChangeTab
    }, null, 8, ["show", "onRefresh", "onChangeTab"]),
    _createVNode(_component_dialogSeatDetails, {
      show: _ctx.isShowRecord,
      "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isShowRecord) = $event)),
      hasSeat: _ctx.orderInfo?.hasSeat,
      useSeat: _ctx.orderInfo?.useSeat
    }, null, 8, ["show", "hasSeat", "useSeat"])
  ], 64))
}