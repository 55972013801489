
import { defineComponent } from 'vue'
import dialogRecharge from '@/components/seat/dialog-recharge.vue';
import dialogSeatDetails from '@/components/seat/dialog-seat-details.vue';
import { getCompanySeatInfo } from '@/api/recharge-record';
import dayjs from 'dayjs';
const curDay = dayjs().format('YYYY-MM-DD') + ' 23:59:59'
const expireDay = dayjs().add(30, 'day').format('YYYY-MM-DD') + ' 23:59:59'

console.log(expireDay);

export default defineComponent({
    data() {
        return {
            isShowRecharge: false,
            isShowRecord: false,
            orderInfo: {} as any
        }
    },
    components: {
        dialogRecharge,
        dialogSeatDetails
    },
    emits: ['refresh', 'changeTab'],
    methods: {
        getData() {
            getCompanySeatInfo({
                pageNum: 1,
                pageSize: 10
            }).then(res=> {
                this.orderInfo = res || {}
            })
        },
        refresh() {
            this.getData()
            this.$emit('refresh')
        },
        handleChangeTab(ev: string) {
            console.log(11111, ev);
            this.$emit('changeTab', ev)
        }
    },
    mounted() {
        this.getData()
    }
})
