
import { defineComponent } from 'vue'
import { cloneDeep } from 'lodash';
export default defineComponent({
    props: {
        tableData: {
            type: Array,
            default() {
                return []
            }
        },
        pageInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            tableHeader: [
                {
                    prop: 'salesScenario',
                    label: '类型'
                },
                {
                    prop: 'buySeatCount',
                    label: '席位数'
                },
                {
                    prop: 'updateTime',
                    label: '生效时间'
                },
                {
                    prop: 'dueDate',
                    label: '过期时间'
                },
                {
                    prop: 'status',
                    label: '状态'
                },
                {
                    prop: 'payMoney',
                    label: '订单金额'
                },
            ],
            pages: {} as any
        }
    },
    computed: {
        typeMap() {
            return (type: number) => {
                console.log(2121, type);
                // 0：首签，1：续签,2:席位购买，3:续费+席位购买,4首签+席位购买
                const map = {
                    0: '首签',
                    1: '续签',
                    2: '席位购买',
                    3: '续费+席位购买',
                    4: '首签+席位购买'
                }
                return (map as any)[type]
            }
        }
    },
    watch: {
        pageInfo: {
            handler(newval) {
                this.pages = cloneDeep(newval)
            },
            deep: true,
            immediate: true
        },
        pages: {
            handler(newval) {
                this.$emit('update:pageInfo', newval)
            },
            deep: true
        }
    },
    methods: {
        handleSizeChange(ev: number) {
            this.pages.pageSize = ev
            this.$emit('doSearch')
        },
        handleCurrentChange(ev: number) {
            this.pages.pageNum = ev
            this.$emit('doSearch')
        }
    }
})
