import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../assets/chart/chart-bar-blue.png'
import _imports_1 from '../assets/chart/chart-line-green.png'
import _imports_2 from '../assets/long-insure/dispatch.png'
import _imports_3 from '../assets/long-insure/worker.png'
import _imports_4 from '../assets/closetwo.png'
import _imports_5 from '../assets/block.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "block" }
const _hoisted_3 = { class: "block-header" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-ai-c"
}
const _hoisted_5 = { class: "block-content" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "balance" }
const _hoisted_9 = { class: "number" }
const _hoisted_10 = { class: "block" }
const _hoisted_11 = { class: "block-header" }
const _hoisted_12 = { class: "block-content" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "" }
const _hoisted_15 = { class: "number" }
const _hoisted_16 = { class: "block" }
const _hoisted_17 = { class: "block-content row nav" }
const _hoisted_18 = { class: "block plan" }
const _hoisted_19 = { class: "block-content plan-list" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "plan-item-head" }
const _hoisted_22 = ["src"]
const _hoisted_23 = { class: "item-head-type" }
const _hoisted_24 = { class: "plan-item-body" }
const _hoisted_25 = { class: "item-body-describe" }
const _hoisted_26 = { class: "item-body-count" }
const _hoisted_27 = {
  key: 0,
  class: "item-body-count"
}
const _hoisted_28 = {
  key: 0,
  class: "plan-item-foot"
}
const _hoisted_29 = { class: "block premium-trend" }
const _hoisted_30 = { class: "block-content" }
const _hoisted_31 = { class: "block" }
const _hoisted_32 = { class: "block-header" }
const _hoisted_33 = { class: "block-content" }
const _hoisted_34 = { class: "flex-x-jus-btn mg-b-10" }
const _hoisted_35 = { class: "mytab" }
const _hoisted_36 = {
  key: 2,
  class: "infomsg"
}
const _hoisted_37 = { class: "flex-x-jus-btn" }
const _hoisted_38 = { class: "fc6B6B86 fs-12 mg-b-12" }
const _hoisted_39 = { class: "fs-14" }
const _hoisted_40 = {
  key: 3,
  class: "infomsg"
}
const _hoisted_41 = { class: "flex-x-jus-btn" }
const _hoisted_42 = { class: "fc6B6B86 fs-12 mg-b-12" }
const _hoisted_43 = { class: "fs-14" }
const _hoisted_44 = { class: "tcbox" }
const _hoisted_45 = { class: "tccontent" }
const _hoisted_46 = { class: "top" }
const _hoisted_47 = { class: "ctbox" }
const _hoisted_48 = { class: "tcbtnbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_popupRecharge = _resolveComponent("popupRecharge")!
  const _component_sunny = _resolveComponent("sunny")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "block-title" }, "账户余额", -1)),
        _createElementVNode("div", {
          class: "block-more point",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (
                    _ctx.$router.push({
                        path: '/longinsure/bill'
                    })
                    ))
        }, [
          (_ctx.user.isPcAdmin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[15] || (_cache[15] = [
                _createElementVNode("div", { class: "" }, "查看详情", -1),
                _createElementVNode("div", { class: "triangle" }, null, -1)
              ])))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "key" }, "余额（元）", -1)),
            _createElementVNode("div", _hoisted_8, [
              _cache[17] || (_cache[17] = _createTextVNode(" ￥")),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.accountBalance), 1)
            ])
          ]),
          _cache[19] || (_cache[19] = _createElementVNode("img", {
            class: "img-chart",
            src: _imports_0
          }, null, -1))
        ]),
        _createElementVNode("button", {
          class: "mini-btn recharge-btn",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.longcz && _ctx.longcz(...args)))
        }, "充值")
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "block-title" }, "当前在保人数", -1)),
        _createElementVNode("div", {
          class: "block-more point",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (
                    _ctx.$router.push({
                        path: '/long-insure/ensure'
                    })
                    ))
        }, _cache[20] || (_cache[20] = [
          _createElementVNode("div", { class: "" }, "查看详情", -1),
          _createElementVNode("div", { class: "triangle" }, null, -1)
        ]))
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _cache[22] || (_cache[22] = _createElementVNode("div", { class: "key" }, "人数", -1)),
            _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.allEnsureCount), 1)
          ]),
          _cache[23] || (_cache[23] = _createElementVNode("img", {
            class: "img-chart",
            src: _imports_1
          }, null, -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _cache[27] || (_cache[27] = _createElementVNode("div", { class: "block-header" }, [
        _createElementVNode("div", { class: "block-title" }, "便捷导航"),
        _createElementVNode("div", { class: "block-more" })
      ], -1)),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", {
          class: "nav-item point",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (
                    _ctx.$router.push({
                        path: '/long-insure-dispatch'
                    })
                    ))
        }, _cache[24] || (_cache[24] = [
          _createElementVNode("img", { src: _imports_2 }, null, -1),
          _createElementVNode("div", null, "被派遣单位", -1)
        ])),
        _createElementVNode("div", {
          class: "nav-item point",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (
                    _ctx.$router.push({
                        path: '/long-insure-worker'
                    })
                    ))
        }, _cache[25] || (_cache[25] = [
          _createElementVNode("img", { src: _imports_3 }, null, -1),
          _createElementVNode("div", null, "人员查询", -1)
        ])),
        _createElementVNode("div", {
          class: "nav-item point",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (
                    _ctx.$router.push({
                        path: '/long-insure-report'
                    })
                    ))
        }, _cache[26] || (_cache[26] = [
          _createElementVNode("img", { src: _imports_2 }, null, -1),
          _createElementVNode("div", null, "报案理赔", -1)
        ]))
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _cache[29] || (_cache[29] = _createElementVNode("div", { class: "block-header" }, [
        _createElementVNode("div", { class: "block-title" }, "我开通的方案")
      ], -1)),
      _createElementVNode("div", _hoisted_19, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyPlans, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(['plan-item point', item.isEnd ? 'disabled' : '']),
            onClick: ($event: any) => (_ctx.toInsureDetail(item.id)),
            style: _normalizeStyle('background: url(' +
                        require('../assets/long-insure/plan-1.png') +
                        ')'
                        )
          }, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("img", {
                class: "item-head-img",
                src: item.insurCompanyLogo,
                alt: ""
              }, null, 8, _hoisted_22),
              _createElementVNode("div", _hoisted_23, _toDisplayString(item.insurCompanyPlanName), 1)
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, _toDisplayString(item.type === 3
                                    ? "灵活保单"
                                    : item.type === 4
                                        ? "按月购买"
                                        : item.type === 5
                                            ? "及时生效"
                                            : ""), 1),
              _createElementVNode("div", _hoisted_26, " 当前在保：" + _toDisplayString(item.ensureCount) + "人 ", 1),
              (item.isEnd)
                ? (_openBlock(), _createElementBlock("div", _hoisted_27, " 到期时间：" + _toDisplayString(item.endTime), 1))
                : _createCommentVNode("", true)
            ]),
            (!item.isEnd)
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, _cache[28] || (_cache[28] = [
                  _createElementVNode("button", { class: "mini-btn recharge-btn" }, "保障中", -1)
                ])))
              : _createCommentVNode("", true)
          ], 14, _hoisted_20))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_29, [
      _cache[31] || (_cache[31] = _createElementVNode("div", { class: "block-header" }, [
        _createElementVNode("div", { class: "block-title" }, "保费趋势")
      ], -1)),
      _createElementVNode("div", _hoisted_30, [
        (_ctx.isTrendEmpty)
          ? (_openBlock(), _createBlock(_component_el_empty, {
              key: 0,
              description: "暂无数据"
            }))
          : _createCommentVNode("", true),
        _cache[30] || (_cache[30] = _createElementVNode("div", { id: "trend" }, null, -1))
      ])
    ]),
    _createElementVNode("div", _hoisted_31, [
      _createElementVNode("div", _hoisted_32, [
        _cache[34] || (_cache[34] = _createElementVNode("div", { class: "block-title" }, "人员统计", -1)),
        _createElementVNode("div", null, [
          _createVNode(_component_el_select, {
            modelValue: _ctx.peopleStatisticsType,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.peopleStatisticsType) = $event)),
            onChange: _ctx.onPeopleStatisticsType,
            class: "people-statistics-type"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                value: 0,
                label: "全部"
              }, {
                default: _withCtx(() => _cache[32] || (_cache[32] = [
                  _createTextVNode("全部")
                ])),
                _: 1
              }),
              _createVNode(_component_el_option, {
                value: 1,
                label: "在保"
              }, {
                default: _withCtx(() => _cache[33] || (_cache[33] = [
                  _createTextVNode("在保")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue", "onChange"])
        ])
      ]),
      _createElementVNode("div", _hoisted_33, [
        (
                    _ctx.peopleStatistics?.occupationGroup?.length === 0 &&
                    _ctx.statisticsTab === 0
                )
          ? (_openBlock(), _createBlock(_component_el_empty, {
              key: 0,
              description: "暂无数据"
            }))
          : _createCommentVNode("", true),
        (
                    _ctx.peopleStatistics?.serveCompanyGroup?.length === 0 &&
                    _ctx.statisticsTab === 1
                )
          ? (_openBlock(), _createBlock(_component_el_empty, {
              key: 1,
              description: "暂无数据"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", {
              class: _normalizeClass({ 'tabitem': true, 'activetab': _ctx.statisticsTab === 0 }),
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onPeopleStatisticsTab(0)))
            }, " 工种(" + _toDisplayString(_ctx.total1) + ")", 3),
            _createElementVNode("div", {
              class: _normalizeClass({ 'tabitem': true, 'activetab': _ctx.statisticsTab === 1 }),
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onPeopleStatisticsTab(1)))
            }, " 被派遣单位(" + _toDisplayString(_ctx.total2) + ")", 3)
          ]),
          _cache[35] || (_cache[35] = _createElementVNode("div", { class: "discrabe" }, [
            _createElementVNode("div", { class: "line" }),
            _createTextVNode(" 在保人数 ")
          ], -1))
        ]),
        (_ctx.statisticsTab === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listone, (item, i) => {
                return (_openBlock(), _createElementBlock("div", { key: i }, [
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("div", _hoisted_38, _toDisplayString(item.occupationName), 1),
                    _createElementVNode("div", _hoisted_39, _toDisplayString(item.count), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_el_progress, {
                      "text-inside": true,
                      "show-text": false,
                      percentage: (item.count / _ctx.maxone) * 100
                    }, null, 8, ["percentage"])
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (_ctx.statisticsTab === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listtwo, (item, i) => {
                return (_openBlock(), _createElementBlock("div", { key: i }, [
                  _createElementVNode("div", _hoisted_41, [
                    _createElementVNode("div", _hoisted_42, _toDisplayString(item.serveCompanyName), 1),
                    _createElementVNode("div", _hoisted_43, _toDisplayString(item.count), 1)
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_el_progress, {
                      "text-inside": true,
                      "show-text": false,
                      percentage: (item.count / _ctx.maxtwo) * 100
                    }, null, 8, ["percentage"])
                  ])
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.isDeletePopup,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.isDeletePopup) = $event)),
      width: "1400px",
      left: "50%",
      top: "5%"
    }, {
      default: _withCtx(() => _cache[36] || (_cache[36] = [
        _createElementVNode("div", { class: "record" }, "开通保险方案", -1)
      ])),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.isRecharge)
      ? (_openBlock(), _createBlock(_component_popupRecharge, {
          key: 0,
          onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.isRecharge = false)),
          useWay: "InsureRechargePeriodZgrs",
          openBank: _ctx.openBank,
          bankNo: _ctx.bankNo,
          bankName: _ctx.bankName,
          onDetermine: _ctx.onDetermine,
          dataTitle: "长期保充值"
        }, null, 8, ["openBank", "bankNo", "bankName", "onDetermine"]))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "tc",
          onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (_ctx.showtcflag = false), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_44, [
            _createElementVNode("div", _hoisted_45, [
              _createElementVNode("div", _hoisted_46, [
                _cache[38] || (_cache[38] = _createElementVNode("div", null, null, -1)),
                _createElementVNode("div", {
                  onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.closetc && _ctx.closetc(...args)))
                }, _cache[37] || (_cache[37] = [
                  _createElementVNode("img", {
                    src: _imports_4,
                    alt: ""
                  }, null, -1)
                ]))
              ]),
              _cache[40] || (_cache[40] = _createElementVNode("div", { class: "bac2" }, null, -1)),
              _createElementVNode("div", _hoisted_47, [
                _cache[39] || (_cache[39] = _createStaticVNode("<div class=\"tctext\" data-v-7ff50ec8><div class=\"ctop\" data-v-7ff50ec8><img src=\"" + _imports_5 + "\" alt=\"\" data-v-7ff50ec8><span class=\"text\" data-v-7ff50ec8>余额不足自动断保规则更新预告</span></div><p class=\"p\" data-v-7ff50ec8> 当系统内<span class=\"boxshadle\" data-v-7ff50ec8>余额不足</span>次日扣款(例如账户余额50元，次日需消耗100元)，将会在当日的 <span class=\"boxshadle\" data-v-7ff50ec8>23:00分系统自动断保，人工无法干预。</span></p><p class=\"p\" data-v-7ff50ec8> 在收到首次短信通知或者群里客服人员通知之后，请务必及时充值， <span class=\"boxshadle\" data-v-7ff50ec8>断保以后发生工伤</span><span class=\"boxshadle\" data-v-7ff50ec8>将无法理赔。</span></p></div><div class=\"tctext\" data-v-7ff50ec8><div class=\"ctop\" data-v-7ff50ec8><img src=\"" + _imports_5 + "\" alt=\"\" data-v-7ff50ec8><span class=\"text\" data-v-7ff50ec8>特别约定</span></div><p class=\"p\" data-v-7ff50ec8> 自2023年9月1日起，本保单所承接年龄超45周岁的保安或保洁人员因工作原因发生的猝死责任限额为主险责任限额的1/3。 </p></div>", 2)),
                _createElementVNode("div", _hoisted_48, [
                  _createElementVNode("div", {
                    class: "btn",
                    onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.closetc && _ctx.closetc(...args)))
                  }, "我知道了")
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_sunny, {
      show: _ctx.showSunnyDialog,
      "onUpdate:show": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.showSunnyDialog) = $event))
    }, null, 8, ["show"])
  ]))
}