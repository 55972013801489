import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header block" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "search" }
const _hoisted_4 = { class: "container block block-alone" }
const _hoisted_5 = { class: "table" }
const _hoisted_6 = { class: "flex flex-ai-c" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "status-box" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "flex flex-ai-c" }
const _hoisted_18 = { class: "decorate-box" }
const _hoisted_19 = { key: 2 }
const _hoisted_20 = { key: 3 }
const _hoisted_21 = { class: "flex gap-10" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["onClick"]
const _hoisted_24 = ["onClick"]
const _hoisted_25 = ["onClick"]
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "alert-box" }
const _hoisted_28 = { class: "video-box" }
const _hoisted_29 = { class: "video-list" }
const _hoisted_30 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Warning = _resolveComponent("Warning")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_batchPayroll = _resolveComponent("batchPayroll")!
  const _component_alertBox = _resolveComponent("alertBox")!
  const _component_LongInsureDispatchDrawer = _resolveComponent("LongInsureDispatchDrawer")!
  const _component_DialogAddCompany = _resolveComponent("DialogAddCompany")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "请输入单位名称",
              class: "input search-input",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
              onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"]))
            }, null, 544), [
              [_vModelText, _ctx.searchText]
            ]),
            _createElementVNode("button", {
              class: "btn",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
            }, "搜索"),
            _createElementVNode("button", {
              class: "btn-white",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args)))
            }, "重置")
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              class: "btn",
              style: {"width":"128px"},
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onShowAddCompany && _ctx.onShowAddCompany(...args)))
            }, "添加被派遣单位")
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_tabs, { "model-value": "detail" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_table, {
                data: _ctx.tableData,
                style: {"width":"100%"},
                "header-row-style": { color: '#202536' },
                "row-style": { color: '#5E617D' },
                onSelectionChange: _ctx.handleSelectionChange
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    prop: "serveCompanyName",
                    label: "被派遣单位",
                    width: "200px"
                  }, {
                    header: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _cache[12] || (_cache[12] = _createElementVNode("div", null, "被派遣单位", -1)),
                        _createVNode(_component_el_tooltip, {
                          class: "box-item",
                          effect: "dark",
                          content: "派遣单位状态，仅代表当前派遣单位是否可以投保",
                          placement: "top-start"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_Warning)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    default: _withCtx((scope) => [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["status", [`status-${scope.row.status}`]])
                          }, _toDisplayString(_ctx.statusMap[scope.row.status]), 3)
                        ]),
                        _createElementVNode("div", null, _toDisplayString(scope.row.serveCompanyName), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    width: "200px",
                    label: "已审核通过的工种"
                  }, {
                    default: _withCtx((scope) => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.occupationList, (item, index) => {
                        return (_openBlock(), _createElementBlock("p", { key: index }, _toDisplayString(item.name) + " " + _toDisplayString('(' + item.level + '类' + ')'), 1))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    width: "110px",
                    label: "最新申请视频"
                  }, {
                    default: _withCtx((scope) => [
                      (_ctx.isFirstApply(scope.row))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            (scope.row?.fileDetailList?.length)
                              ? (_openBlock(), _createElementBlock("p", {
                                  key: 0,
                                  style: {"color":"#1989FA"},
                                  class: "point",
                                  onClick: ($event: any) => (_ctx.onFileDetail(scope.row.fileDetailList))
                                }, "点击查看", 8, _hoisted_10))
                              : (_openBlock(), _createElementBlock("p", _hoisted_11, "暂无视频"))
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            (scope.row.occupationRecord?.fileDetails?.length)
                              ? (_openBlock(), _createElementBlock("p", {
                                  key: 0,
                                  style: {"color":"#1989FA"},
                                  class: "point",
                                  onClick: ($event: any) => (_ctx.onFileDetail(_ctx.mapUrl(scope.row.fileDetailList)))
                                }, "点击查看", 8, _hoisted_13))
                              : (_openBlock(), _createElementBlock("p", _hoisted_14, "暂无视频"))
                          ]))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    width: "200px",
                    prop: "createTime",
                    label: "最新申请时间"
                  }, {
                    default: _withCtx((scope) => [
                      (_ctx.isFirstApply(scope.row))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(scope.row.createTime), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(scope.row.occupationRecord?.createTime || '--'), 1))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    width: "120px",
                    label: "最新申请状态"
                  }, {
                    header: _withCtx(() => [
                      _createElementVNode("div", _hoisted_17, [
                        _cache[13] || (_cache[13] = _createElementVNode("div", null, "最新申请状态", -1)),
                        _createVNode(_component_el_tooltip, {
                          class: "box-item",
                          effect: "dark",
                          content: "最新上传视频的申请状态",
                          placement: "top-start"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_icon, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_Warning)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    default: _withCtx((scope) => [
                      _createElementVNode("div", _hoisted_18, [
                        (_ctx.isFirstApply(scope.row))
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["decorate", [`decorate-${ scope.row.status}`]])
                            }, null, 2))
                          : (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: _normalizeClass(["decorate", [`decorate-${scope.row.occupationRecord?.status}`]])
                            }, null, 2)),
                        (_ctx.isFirstApply(scope.row))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.statusMap[scope.row.status]), 1))
                          : (_openBlock(), _createElementBlock("p", _hoisted_20, _toDisplayString(_ctx.statusMap[scope.row.occupationRecord?.status]), 1))
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "特别约定",
                    prop: "remark"
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "驳回理由",
                    prop: "rejectRemark"
                  }),
                  _createVNode(_component_el_table_column, {
                    width: "170px",
                    label: "操作",
                    fixed: "right"
                  }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("div", _hoisted_21, [
                        (scope.row.status===1 && (!scope.row.occupationRecord || scope.row.occupationRecord?.status===1))
                          ? (_openBlock(), _createElementBlock("p", {
                              key: 0,
                              style: {"color":"#FF7E68"},
                              class: "point",
                              onClick: ($event: any) => (
						_ctx.deleteDispatchExamine(scope.row.id)
						)
                            }, " 删除 ", 8, _hoisted_22))
                          : _createCommentVNode("", true),
                        (!scope.row.status)
                          ? (_openBlock(), _createElementBlock("p", {
                              key: 1,
                              class: "point fc-blue",
                              onClick: ($event: any) => (
						_ctx.cancelDispatchExamine(scope.row.id)
						)
                            }, " 撤销 ", 8, _hoisted_23))
                          : _createCommentVNode("", true),
                        (scope.row.status === 1 && scope.row.occupationRecord?.status===0)
                          ? (_openBlock(), _createElementBlock("p", {
                              key: 2,
                              class: "point fc-blue",
                              onClick: ($event: any) => (
						_ctx.onWithDrawOccupation(scope.row.occupationRecord.id)
						)
                            }, " 撤销 ", 8, _hoisted_24))
                          : _createCommentVNode("", true),
                        _createElementVNode("p", {
                          class: "point fc-blue",
                          onClick: ($event: any) => (_ctx.onShowDetailDrawer(scope.row))
                        }, " 详情 ", 8, _hoisted_25),
                        (scope.row.status === 1 && scope.row.occupationRecord?.status !== 0)
                          ? (_openBlock(), _createElementBlock("p", {
                              key: 3,
                              class: "point fc-blue",
                              onClick: ($event: any) => (_ctx.onApplyNewOccupation(scope.row.serveCompanyId, scope.row.id))
                            }, " 申请 ", 8, _hoisted_26))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data", "onSelectionChange"]),
              _createVNode(_component_el_pagination, {
                class: "pagination",
                currentPage: _ctx.pageNum,
                "onUpdate:currentPage": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pageNum) = $event)),
                "page-size": _ctx.pageSize,
                "onUpdate:pageSize": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pageSize) = $event)),
                "page-sizes": [10, 30, 50, 100],
                layout: "total, sizes, prev, pager, next, jumper",
                total: _ctx.total,
                onSizeChange: _ctx.handleSizeChange,
                onCurrentChange: _ctx.getPayrollList
              }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
            ])
          ]),
          _: 1
        }),
        (_ctx.isBatchPay)
          ? (_openBlock(), _createBlock(_component_batchPayroll, {
              key: 0,
              onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isBatchPay = false)),
              onComplete: _ctx.getPayrollList,
              isType: "payroll"
            }, null, 8, ["onComplete"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.videoList.length)
        ? (_openBlock(), _createBlock(_component_alertBox, {
            key: 0,
            "data-title": (_ctx.videoIndex + 1) + '/' + _ctx.videoList.length,
            isCloseType: true,
            onClose: _ctx.onVideoCallback
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", null, [
                    _createElementVNode("i", {
                      style: {"font-size":"50px"},
                      class: "el-icon el-icon-arrow-left",
                      onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.onVideoUpper && _ctx.onVideoUpper(...args)))
                    })
                  ]),
                  _createElementVNode("div", _hoisted_29, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videoList, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        (_ctx.videoIndex === index)
                          ? (_openBlock(), _createElementBlock("video", {
                              key: 0,
                              class: "video",
                              src: item,
                              preload: "",
                              controls: ""
                            }, null, 8, _hoisted_30))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("i", {
                      style: {"font-size":"50px"},
                      class: "el-icon el-icon-arrow-right",
                      onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onVideoNext && _ctx.onVideoNext(...args)))
                    })
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["data-title", "onClose"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_LongInsureDispatchDrawer, {
      show: _ctx.showDetail,
      "onUpdate:show": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showDetail) = $event)),
      id: _ctx.itemDetail.id,
      onSuccess: _ctx.getPayrollList
    }, null, 8, ["show", "id", "onSuccess"]),
    _createVNode(_component_DialogAddCompany, {
      show: _ctx.showAddCompany,
      "onUpdate:show": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.showAddCompany) = $event)),
      "cur-company-id": _ctx.curCompanyId,
      id: _ctx.curId,
      "add-type": _ctx.addType,
      onSuccess: _ctx.getPayrollList
    }, null, 8, ["show", "cur-company-id", "id", "add-type", "onSuccess"])
  ], 64))
}