
import { defineComponent } from 'vue'
import { insureCaseDetail } from '@/api/insure-deduction'
export default defineComponent({
    name: 'InsureTeamReportDetail',
    components: {},
    data () {
        return {
            processList: [] as any,
            caseInfo: {} as any
        }
    },
    created () {
        this.getReportDetailInfo()
    },
    methods: {
        async getReportDetailInfo () {
            console.log(this.$route.params.id, 'this.$route.params.id')
            const result = await insureCaseDetail(this.$route.params.id)
            console.log(result, 'result')
            if (result.results) {
                result.results.forEach((item:{reportSolveResult:string, remark:string, createTime:string, desc:number, type:string, hollow:boolean}) => {
                    this.processList.push({
                        type: 'primary',
                        hollow: true,
                        reportSolveResult: item.reportSolveResult,
                        timestamp: item.createTime,
                        remark: item.remark,
                        desc: item.desc
                    })
                })
            }

            this.caseInfo = result
        }
    }
})
