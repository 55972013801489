import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "search" }
const _hoisted_2 = { class: "table" }
const _hoisted_3 = { class: "decorate-box" }
const _hoisted_4 = { class: "foot" }

import alertBox from './alert-box.vue'

import { defineComponent } from 'vue'
import { getStaffRecordList } from '@/api/long-insure'

const __default__ = defineComponent({
    props: ['id', 'insur', 'type'],
    // async created () {
    //     const result = await getStaffRecordList({
    //         pageNum: 1,
    //         pageSize: 10,
    //         policyStatus: '',
    //         searchText: this.searchText
    //     })
    //     this.list = result.list
    //     this.total = result.total
    // },
    mounted () {
        console.log(this.$props.insur, 'this.$props.insur')
        console.log(this.$props.id, 'this.$props.id')
        console.log(this.$props.type, 'this.$props.type')
        this.getStaffRecordList()
    },
    data () {
        return {
            list: [] as any,
            pageSize: 10,
            pageNum: 1,
            total: 0,
            selection: [],
            searchText: '',
            insurCompany: this.$props.insur,
            // eslint-disable-next-line vue/no-dupe-keys
            type: this.$props.type
        }
    },
    methods: {
        async getStaffRecordList () {
            console.log('调用啦')

            // .filter((item: any) => item?.policyStatus === 1)
            console.log(this.type === 'add', '2222222222222222222222222')
            let result
            if (this.type === 'add') {
                // result.list.map((item: any) => {
                //     item.encryptionIdCardNo = item.idCardNo.replace(
                //         /(.{3})(?:\d+)(.{4})$/,
                //         '$1*********$2'
                //     )
                // })
                result = await getStaffRecordList({
                    companyPlanId: Number(this.$props.id) as number,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    searchText: this.searchText,
                    insurCompany: this.$props.insur,
                    insurValidFlag: true,
                    insurExistEndDateFlag: true
                })
                console.log(result.list, 'resultresultresultresultresultresult333333333333')
                // result.list = result.list.filter((item: any) => item?.policyStatus !== 1 && item?.policyStatus !== 0)
            } else {
                // result.list.map((item: any) => {
                //     item.encryptionIdCardNo = item.idCardNo.replace(
                //         /(.{3})(?:\d+)(.{4})$/,
                //         '$1*********$2'
                //     )
                // })
                result = await getStaffRecordList({
                    companyPlanId: Number(this.$props.id) as number,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    policyStatus: '',
                    searchText: this.searchText,
                    insurCompany: this.$props.insur,
                    insurValidFlag: false,
                    insurEmptyEndDateFlag: true
                })
                console.log(result.list, 'resultresultresultresultresultresult')
                result.list = result.list.filter((item: any) => item?.policyStatus !== 2 && !item?.insurEndTime)
            }
            // eslint-disable-next-line no-undef
            result.list.map((item: any) => {
                item.encryptionIdCardNo = item.idCardNo.replace(
                    /(.{3})(?:\d+)(.{4})$/,
                    '$1*********$2'
                )
            })
            // eslint-disable-next-line no-undef
            console.log(result.list.length, 'resultresultresultresultresultresult222222222222222222222222222222')
            this.list = result.list
            this.total = result.total
        },
        changeNum (num: number | string) {
            console.log(num, 'numnum')
            this.getStaffRecordList()
        },
        changeSize () {
            this.getStaffRecordList()
        },
        handleSelectionChange (event: any) {
            this.selection = event
            console.log(this.selection, 'this.selection')
        },
        determineSelection () {
            console.log('点击啦')
            this.$emit('complete', this.selection)
        },
        search () {
            this.getStaffRecordList()
        },
        onReset () {
            this.searchText = ''
            this.getStaffRecordList()
        }
    }
})


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'personnel-query-popup',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createBlock(alertBox, { "data-title": "员工选择" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          placeholder: "请输入",
          class: "input search-input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"]))
        }, null, 544), [
          [_vModelText, _ctx.searchText]
        ]),
        _createElementVNode("button", {
          class: "btn btn-padding",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
        }, "搜索"),
        _createElementVNode("button", {
          class: "btn-white",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args)))
        }, " 重置 ")
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_table, {
          data: _ctx.list,
          style: {"width":"100%"},
          "header-row-style": { color: '#202536' },
          "row-style": { color: '#5E617D' },
          onSelectionChange: _ctx.handleSelectionChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, { type: "selection" }),
            _createVNode(_component_el_table_column, {
              type: "index",
              label: "序号"
            }),
            _createVNode(_component_el_table_column, { label: "状态" }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", {
                    class: "decorate",
                    style: _normalizeStyle({
                                    background:
                                        scope.row.policyStatus == 1
                                            ? '#00D679 '
                                            : scope.row.policyStatus == 2
                                            ? '#FF7E68'
                                            : '#1989FA'
                                })
                  }, null, 4),
                  _createElementVNode("p", null, _toDisplayString(scope.row.policyStatus == 1
                    ? '保障中'
                    : scope.row.policyStatus == 2
                      ? '不在保'
                      : '待生效'), 1)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "姓名",
              prop: "userName"
            }),
            _createVNode(_component_el_table_column, {
              label: "身份证号",
              prop: "encryptionIdCardNo"
            }),
            _createVNode(_component_el_table_column, {
              prop: "companyPlanName",
              label: "方案类型"
            }),
            _createVNode(_component_el_table_column, {
              prop: "serveCompanyName",
              label: "被派遣单位"
            })
          ]),
          _: 1
        }, 8, ["data", "onSelectionChange"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_pagination, {
            class: "pagination",
            currentPage: _ctx.pageNum,
            "onUpdate:currentPage": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pageNum) = $event)),
            "page-size": _ctx.pageSize,
            "onUpdate:pageSize": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pageSize) = $event)),
            "page-sizes": [10, 30, 50, 100],
            layout: "total, sizes, prev, pager, next, jumper",
            total: _ctx.total,
            onSizeChange: _ctx.changeSize,
            onCurrentChange: _ctx.changeNum
          }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"]),
          _createElementVNode("button", {
            class: "btn btn-red",
            style: {"background":"#1989FA"},
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.determineSelection && _ctx.determineSelection(...args)))
          }, " 确定 ")
        ])
      ])
    ]),
    _: 1
  }))
}
}

})