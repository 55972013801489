import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"
import _imports_0 from '../../../assets/img/long-insure-dispatch/bg-2.png'
import _imports_1 from '../../../assets/img/long-insure-dispatch/icon-title.png'


const _hoisted_1 = { class: "occupation-card" }
const _hoisted_2 = {
  key: 0,
  class: "view"
}
const _hoisted_3 = { class: "item-content" }
const _hoisted_4 = { class: "left flex flex-ai-c" }
const _hoisted_5 = { class: "order" }
const _hoisted_6 = { class: "fs-14 mg-r-5" }
const _hoisted_7 = { class: "fs-12 fc-grey" }

import { ref } from 'vue'
import FullVideo from '@/components/VideoComponent/FullVideo.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'OccupationCard',
  props: {
    listData: {
        type: Array,
        default() {
            return []
        }
    },
    showVideo: {
        type: Boolean,
        default: false
    },
    videoList: {
        type: Array,
        default() {
            return []
        }
    }
},
  setup(__props) {

const props = __props

const videoVisible = ref(false)

const onShowVideo = () => {
    videoVisible.value = true
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        class: "item-header-bg",
        src: _imports_0,
        alt: ""
      }, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "title-box flex flex-ai-c" }, [
        _createElementVNode("img", {
          src: _imports_1,
          alt: "",
          class: "icon-title-head"
        }),
        _createElementVNode("div", { class: "fs-14 fc-white" }, "可用工种")
      ], -1)),
      (__props.showVideo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "fc-green fs-14 pointer",
              onClick: onShowVideo
            }, "查看视频")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.listData, (i, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["item flex flex-jc-sb", { 'set-margin': index === 0 }])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(index + 1), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(i.level) + "类", 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(i.name), 1)
            ])
          ], 2))
        }), 128))
      ])
    ]),
    _createVNode(FullVideo, {
      show: videoVisible.value,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((videoVisible).value = $event)),
      "video-list": __props.videoList
    }, null, 8, ["show", "video-list"])
  ], 64))
}
}

})