import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '../assets/common/arrow.png'
import _imports_1 from '../assets/long-insure/add-safe.png'
import _imports_2 from '../assets/long-insure/reduce-safe.png'
import _imports_3 from '../assets/long-insure/success.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "header block block-active" }
const _hoisted_3 = { class: "header-left" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "header-left-info" }
const _hoisted_6 = { class: "left-info-title" }
const _hoisted_7 = { class: "left-info-date" }
const _hoisted_8 = { class: "header-right" }
const _hoisted_9 = { class: "block block-alone" }
const _hoisted_10 = { class: "table-top-title" }
const _hoisted_11 = { class: "title-left" }
const _hoisted_12 = { class: "title" }
const _hoisted_13 = {
  key: 0,
  class: "search"
}
const _hoisted_14 = { class: "table" }
const _hoisted_15 = { class: "operation-box" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "operation-box" }
const _hoisted_19 = ["onClick"]
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  class: "edit-popup-box",
  style: {"width":"640px"}
}
const _hoisted_22 = {
  key: 0,
  class: "row"
}
const _hoisted_23 = {
  key: 1,
  class: "row"
}
const _hoisted_24 = {
  key: 2,
  class: "row"
}
const _hoisted_25 = {
  key: 3,
  class: "row"
}
const _hoisted_26 = {
  key: 4,
  class: "row",
  style: {"align-items":"start !important"}
}
const _hoisted_27 = { style: {"width":"30px","flex-shrink":"0"} }
const _hoisted_28 = {
  key: 0,
  style: {"width":"300px","text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"}
}
const _hoisted_29 = {
  key: 1,
  style: {"width":"300px"}
}
const _hoisted_30 = { class: "alert-bottom" }
const _hoisted_31 = { class: "edit-popup-box" }
const _hoisted_32 = { class: "row" }
const _hoisted_33 = { class: "row" }
const _hoisted_34 = {
  key: 0,
  class: "row"
}
const _hoisted_35 = {
  key: 0,
  style: {"padding":"10px"}
}
const _hoisted_36 = { key: 1 }
const _hoisted_37 = {
  key: 1,
  class: "row"
}
const _hoisted_38 = {
  key: 2,
  class: "row",
  style: {"align-items":"start !important"}
}
const _hoisted_39 = { style: {"width":"30px","flex-shrink":"0"} }
const _hoisted_40 = {
  key: 0,
  style: {"width":"300px","text-overflow":"ellipsis","white-space":"nowrap","overflow":"hidden"}
}
const _hoisted_41 = {
  key: 1,
  style: {"width":"300px"}
}
const _hoisted_42 = { class: "alert-bottom" }
const _hoisted_43 = { class: "edit-popup-box" }
const _hoisted_44 = { class: "edit-popup-box" }
const _hoisted_45 = { class: "remind-popup-box" }
const _hoisted_46 = { class: "remind-i" }
const _hoisted_47 = { class: "remind-i-name" }
const _hoisted_48 = { class: "remind-i" }
const _hoisted_49 = {
  key: 0,
  class: "operation-type-img",
  src: _imports_1
}
const _hoisted_50 = {
  key: 1,
  class: "operation-type-img",
  src: _imports_2
}
const _hoisted_51 = { class: "remind-i" }
const _hoisted_52 = { class: "remind-i-name" }
const _hoisted_53 = { class: "remind-i" }
const _hoisted_54 = { class: "remind-i-name" }
const _hoisted_55 = {
  class: "remind-i alert-bottom",
  style: {"box-shadow":"none"}
}
const _hoisted_56 = { class: "success-popup-box" }
const _hoisted_57 = { class: "num" }
const _hoisted_58 = { class: "form-error" }
const _hoisted_59 = { class: "error-table" }
const _hoisted_60 = { class: "receive" }
const _hoisted_61 = { class: "insure-box" }
const _hoisted_62 = { class: "insure-box-info" }
const _hoisted_63 = { class: "title" }
const _hoisted_64 = { class: "name-box" }
const _hoisted_65 = {
  key: 0,
  class: "name"
}
const _hoisted_66 = { class: "num" }
const _hoisted_67 = {
  key: 1,
  class: "name"
}
const _hoisted_68 = { class: "num" }
const _hoisted_69 = {
  key: 2,
  class: "name"
}
const _hoisted_70 = { class: "num" }
const _hoisted_71 = { class: "name" }
const _hoisted_72 = { class: "num" }
const _hoisted_73 = { class: "name" }
const _hoisted_74 = { class: "num" }
const _hoisted_75 = { class: "insure-box-btn insure-box-btn-active" }
const _hoisted_76 = { class: "insure-box" }
const _hoisted_77 = { class: "insure-box-info" }
const _hoisted_78 = { class: "name-box" }
const _hoisted_79 = { class: "name" }
const _hoisted_80 = { class: "num" }
const _hoisted_81 = { class: "name" }
const _hoisted_82 = { class: "num" }
const _hoisted_83 = { class: "name" }
const _hoisted_84 = { class: "num" }
const _hoisted_85 = { class: "insure-box-btn insure-box-active insure-box-btn-active" }
const _hoisted_86 = { class: "foot" }
const _hoisted_87 = { class: "foot-operation" }
const _hoisted_88 = { class: "select-delete" }
const _hoisted_89 = {
  key: 0,
  class: "select-delete"
}
const _hoisted_90 = { class: "num" }
const _hoisted_91 = { class: "foot-btn-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_personnelQueryPopup = _resolveComponent("personnelQueryPopup")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_alert_box = _resolveComponent("alert-box")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_el_alert = _resolveComponent("el-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _ctx.tioInfo.insurCompanyLogo,
            alt: "",
            class: "portrait"
          }, null, 8, _hoisted_4),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.tioInfo.insurCompanyPlanName), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.tioInfo.beginTime) + " 至 " + _toDisplayString(_ctx.tioInfo.endTime || '暂无到期时间'), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", {
            class: "btn btn-green",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onlineSelection && _ctx.onlineSelection(...args)))
          }, _cache[47] || (_cache[47] = [
            _createElementVNode("i", { class: "el-icon el-icon-upload2" }, null, -1),
            _createTextVNode(" 在线选择 ")
          ])),
          _createElementVNode("button", {
            class: "btn btn-white-blue",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.sglr && _ctx.sglr(...args)))
          }, _cache[48] || (_cache[48] = [
            _createElementVNode("i", { class: "el-icon el-icon-edit" }, null, -1),
            _createTextVNode(" 手工录入 ")
          ])),
          _createVNode(_component_el_upload, {
            class: "upload-demo",
            drag: false,
            disabled: false,
            "show-file-list": false,
            "file-list": _ctx.fileList,
            "before-upload": _ctx.onUpFile,
            "http-request": () => { },
            accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }, {
            default: _withCtx(() => _cache[49] || (_cache[49] = [
              _createElementVNode("button", { class: "btn btn-white-blue" }, [
                _createElementVNode("i", { class: "el-icon el-icon-folder-add" }),
                _createTextVNode(" 导入名单 ")
              ], -1)
            ])),
            _: 1
          }, 8, ["file-list", "before-upload"]),
          _createElementVNode("button", {
            class: "btn btn-red",
            style: {"background":"#1989FA"},
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.downLoadTemp && _ctx.downLoadTemp(...args)))
          }, _cache[50] || (_cache[50] = [
            _createElementVNode("i", { class: "el-icon el-icon-download" }, null, -1),
            _createElementVNode("a", {
              class: "down-templete",
              style: {"position":"relative","z-index":"10","text-decoration":"none","color":"#ffffff"}
            }, "模板下载", -1)
          ]))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, " 本次" + _toDisplayString(_ctx.insurance === 'reduce' ? '减保' : '加保') + "人员（" + _toDisplayString(_ctx.insurance === 'reduce'
                                ? _ctx.addList.filter(item => !item.errMsg).length
                                : _ctx.addList.length) + "）人 ", 1),
            (_ctx.insurance === 'add')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.searchType,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchType) = $event)),
                    filterable: "",
                    placeholder: "派遣单位",
                    class: "screen-i search-type"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyList, (item, index) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: index,
                          label: item,
                          value: index
                        }, null, 8, ["label", "value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createElementVNode("button", {
                    class: "btn btn-padding",
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
                  }, " 搜索 "),
                  _createElementVNode("button", {
                    class: "btn-white",
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args)))
                  }, " 重置 ")
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _cache[51] || (_cache[51] = _createElementVNode("span", { class: "effective" }, "选择生效日期", -1)),
            _createVNode(_component_el_date_picker, {
              disabled: _ctx.datePickerDisabled,
              modelValue: _ctx.date,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.date) = $event)),
              type: "date",
              placeholder: "选择日期",
              format: "YYYY-MM-DD",
              "value-format": "YYYY-MM-DD"
            }, null, 8, ["disabled", "modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          (_ctx.insurance === 'add')
            ? (_openBlock(), _createBlock(_component_el_table, {
                key: 0,
                data: _ctx.addList,
                style: {"width":"100%"},
                "header-row-style": { color: '#202536' },
                "row-style": { color: '#5E617D' },
                onSelectionChange: _ctx.handleSelectionChange,
                "header-cell-style": { background: '#F7F8FC' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    type: "selection",
                    width: "55"
                  }),
                  _createVNode(_component_el_table_column, {
                    type: "index",
                    label: "序号",
                    width: "50"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "userName",
                    label: "姓名"
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "身份证号",
                    prop: "idCardNo",
                    width: "300px"
                  }, {
                    default: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString(scope.row.idCardNo.replace(
                                    /(.{3})(?:\d+)(.{4})$/,
                                    '$1*********$2'
                                )), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "被派遣单位",
                    prop: "serveCompanyName"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "occupationName",
                    label: "所属工种"
                  }, {
                    default: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString(scope.row.occupationId
                                    ? scope.row.occupationName
                                    : '') + " " + _toDisplayString(scope.row.level
                                    ? '(' + scope.row.level + '类)'
                                    : ''), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "specialAppoint",
                    label: "特别约定"
                  }, {
                    default: _withCtx((scope) => [
                      _createTextVNode(_toDisplayString(scope.row.specialAppoint
                                    ? scope.row.specialAppoint
                                    : '--'), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_table_column, { label: "操作" }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("div", _hoisted_15, [
                        _createElementVNode("p", {
                          style: {"color":"#1989FA"},
                          class: "point",
                          onClick: ($event: any) => (
                                    _ctx.editPersonnelInfo(
                                        scope.row.idCardNo,
                                        'alone',
                                        scope.row
                                    )
                                    )
                        }, " 编辑 ", 8, _hoisted_16),
                        _cache[52] || (_cache[52] = _createTextVNode(" | ")),
                        _createElementVNode("p", {
                          style: {"color":"#FF7E68"},
                          class: "point",
                          onClick: ($event: any) => (_ctx.deletePersonnel(scope.row.idCardNo))
                        }, " 删除 ", 8, _hoisted_17)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data", "onSelectionChange"]))
            : _createCommentVNode("", true),
          (_ctx.insurance === 'reduce')
            ? (_openBlock(), _createBlock(_component_el_table, {
                key: 1,
                data: _ctx.addList,
                style: {"width":"100%"},
                "header-row-style": { color: '#202536' },
                "row-style": _ctx.reduceTable,
                sortable: "true",
                "default-sort": { prop: 'errMsg', order: 'descending' },
                onSelectionChange: _ctx.handleSelectionChange,
                "header-cell-style": { background: '#F7F8FC' }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    type: "selection",
                    width: "55",
                    selectable: _ctx.checkMaterialStatus
                  }, null, 8, ["selectable"]),
                  _createVNode(_component_el_table_column, {
                    type: "index",
                    label: "序号",
                    width: "50"
                  }),
                  _createVNode(_component_el_table_column, {
                    prop: "userName",
                    label: "姓名"
                  }),
                  _createVNode(_component_el_table_column, {
                    label: "身份证号",
                    prop: "idCardNo"
                  }),
                  _createVNode(_component_el_table_column, { label: "操作" }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("p", {
                          style: {"color":"#1989FA"},
                          class: "point",
                          onClick: ($event: any) => (
                                    _ctx.editPersonnelInfo(
                                        scope.row.idCardNo,
                                        'alone',
                                        scope.row
                                    )
                                    )
                        }, " 编辑 ", 8, _hoisted_19),
                        _cache[53] || (_cache[53] = _createTextVNode(" | ")),
                        _createElementVNode("p", {
                          style: {"color":"#FF7E68"},
                          class: "point",
                          onClick: ($event: any) => (_ctx.deletePersonnel(scope.row.idCardNo))
                        }, " 删除 ", 8, _hoisted_20)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data", "row-style", "onSelectionChange"]))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.isPopup)
        ? (_openBlock(), _createBlock(_component_personnelQueryPopup, {
            key: 0,
            id: _ctx.companyPlanId,
            type: this.$route.params.type,
            onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isPopup = false)),
            onComplete: _ctx.insureComplete,
            insur: _ctx.tioInfo.insurCompany
          }, null, 8, ["id", "type", "onComplete", "insur"]))
        : _createCommentVNode("", true),
      (_ctx.isEditPopup)
        ? (_openBlock(), _createBlock(_component_alert_box, {
            key: 1,
            class: "alert-box",
            "data-Title": "设置工种",
            onClose: _ctx.onCancelModification
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_21, [
                (_ctx.isEditTypePopup === 'alone')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                      _cache[54] || (_cache[54] = _createElementVNode("div", { class: "name" }, [
                        _createElementVNode("span", { class: "important" }, "*"),
                        _createTextVNode(" 姓名 ")
                      ], -1)),
                      _createVNode(_component_el_input, {
                        placeholder: "请输入姓名",
                        modelValue: _ctx.manualName,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.manualName) = $event))
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isEditTypePopup === 'alone')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                      _cache[55] || (_cache[55] = _createElementVNode("div", { class: "name" }, [
                        _createElementVNode("span", { class: "important" }, "*"),
                        _createTextVNode(" 身份证 ")
                      ], -1)),
                      _createVNode(_component_el_input, {
                        placeholder: "请输入身份证",
                        modelValue: _ctx.manualCode,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.manualCode) = $event))
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.insurance === 'add')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                      _cache[56] || (_cache[56] = _createElementVNode("div", { class: "name" }, [
                        _createElementVNode("span", { class: "important" }, "*"),
                        _createTextVNode(" 被派遣单位 ")
                      ], -1)),
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.companyDetailId,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.companyDetailId) = $event)),
                        filterable: "",
                        placeholder: "选择派遣单位",
                        class: "screen-i search-type",
                        onClick: _ctx.getCompanyDetailList,
                        onChange: _ctx.onSelectCompany
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyDetailList, (item, index) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              disabled: item.isBlacklist || item.isForbidden,
                              key: index,
                              label: item.serveCompanyName,
                              value: item.serveCompanyId
                            }, null, 8, ["disabled", "label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onClick", "onChange"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.insurance === 'add')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _cache[57] || (_cache[57] = _createElementVNode("div", { class: "name" }, [
                        _createElementVNode("span", { class: "important" }, "*"),
                        _createTextVNode(" 工种 ")
                      ], -1)),
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.workTypeId,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.workTypeId) = $event)),
                        onFocus: _ctx.onFocuse,
                        filterable: "",
                        placeholder: "选择工种",
                        class: "screen-i search-type"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workTypeList, (item, index) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: index,
                              label: item.name + '(' + item.level + '类)',
                              value: item.id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onFocus"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.specialAppoint)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createVNode(_component_el_checkbox, {
                          modelValue: _ctx.specialAppointisSelect,
                          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.specialAppointisSelect) = $event)),
                          size: "large"
                        }, null, 8, ["modelValue"])
                      ]),
                      (_ctx.specialAppoint && !_ctx.isopenmore)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(_ctx.specialAppoint), 1))
                        : _createCommentVNode("", true),
                      (_ctx.specialAppoint && _ctx.isopenmore)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(_ctx.specialAppoint), 1))
                        : _createCommentVNode("", true),
                      (_ctx.showmore && !_ctx.isopenmore)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 2,
                            onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.changeisopenmore(0))),
                            style: {"width":"16px","height":"16px","transform":"rotate(90deg)","cursor":"pointer","margin-top":"10px"},
                            src: _imports_0,
                            alt: ""
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.showmore && _ctx.isopenmore)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 3,
                            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.changeisopenmore(1))),
                            style: {"width":"16px","height":"16px","transform":"rotate(-90deg)","cursor":"pointer","margin-top":"10px"},
                            src: _imports_0,
                            alt: ""
                          }))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("button", {
                  class: "alert-bottom-btn btn btn-white-blue btn-alone white",
                  onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.onCancelModification && _ctx.onCancelModification(...args)))
                }, " 取消 "),
                _createElementVNode("button", {
                  class: "alert-bottom-btn btn btn-green btn-alone",
                  onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.onConfirmModification && _ctx.onConfirmModification(...args)))
                }, " 确定 ")
              ])
            ]),
            _: 1
          }, 8, ["onClose"]))
        : _createCommentVNode("", true),
      (_ctx.isManualPopup)
        ? (_openBlock(), _createBlock(_component_alert_box, {
            key: 2,
            class: "alert-box",
            "data-Title": "手工录入",
            onClose: _cache[26] || (_cache[26] = ($event: any) => (_ctx.isManualPopup = false))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("div", _hoisted_32, [
                  _cache[58] || (_cache[58] = _createElementVNode("div", { class: "name" }, [
                    _createElementVNode("span", { class: "important" }, "*"),
                    _createTextVNode(" 姓名 ")
                  ], -1)),
                  _createVNode(_component_el_input, {
                    placeholder: "请输入姓名",
                    modelValue: _ctx.manualName,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.manualName) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_33, [
                  _cache[59] || (_cache[59] = _createElementVNode("div", { class: "name" }, [
                    _createElementVNode("span", { class: "important" }, "*"),
                    _createTextVNode(" 身份证 ")
                  ], -1)),
                  _createVNode(_component_el_input, {
                    placeholder: "请输入身份证",
                    modelValue: _ctx.manualCode,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.manualCode) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                (_ctx.insurance === 'add')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                      _cache[62] || (_cache[62] = _createElementVNode("div", { class: "name" }, [
                        _createElementVNode("span", { class: "important" }, "*"),
                        _createTextVNode(" 被派遣单位 ")
                      ], -1)),
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.companyDetailId,
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.companyDetailId) = $event)),
                        filterable: "",
                        placeholder: "选择派遣单位",
                        class: "screen-i search-type",
                        onClick: _ctx.getCompanyDetailList,
                        onChange: _ctx.onSelectCompany
                      }, {
                        empty: _withCtx(() => [
                          (_ctx.loaded)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_35, _cache[60] || (_cache[60] = [
                                _createElementVNode("div", null, "无审核通过的\"被派遣单位\"", -1),
                                _createElementVNode("div", { style: {"margin-top":"10px","color":"red"} }, "解决方法：请在\"审核派遣单位页\" 审核 \"派遣单位\" ", -1)
                              ])))
                            : (_openBlock(), _createElementBlock("div", _hoisted_36, _cache[61] || (_cache[61] = [
                                _createElementVNode("div", { style: {"padding":"10px","text-align":"center","color":"#ccc"} }, "加载中", -1)
                              ])))
                        ]),
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyDetailList, (item, index) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              disabled: item.isBlacklist || item.isForbidden,
                              key: index,
                              label: item.serveCompanyName,
                              value: item.serveCompanyId
                            }, null, 8, ["disabled", "label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onClick", "onChange"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.insurance === 'add')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                      _cache[63] || (_cache[63] = _createElementVNode("div", { class: "name" }, [
                        _createElementVNode("span", { class: "important" }, "*"),
                        _createTextVNode(" 工种 ")
                      ], -1)),
                      _createVNode(_component_el_select, {
                        modelValue: _ctx.workTypeId,
                        "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.workTypeId) = $event)),
                        filterable: "",
                        placeholder: "选择工种",
                        onFocus: _ctx.onFocuse,
                        class: "screen-i search-type"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workTypeList, (item, index) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: index,
                              label: item.name + '(' + item.level + '类)',
                              value: item.id
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onFocus"])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.specialAppoint)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                      _createElementVNode("div", _hoisted_39, [
                        _createVNode(_component_el_checkbox, {
                          modelValue: _ctx.specialAppointisSelect,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.specialAppointisSelect) = $event)),
                          size: "large"
                        }, null, 8, ["modelValue"])
                      ]),
                      (_ctx.specialAppoint && !_ctx.isopenmore)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_40, _toDisplayString(_ctx.specialAppoint), 1))
                        : _createCommentVNode("", true),
                      (_ctx.specialAppoint && _ctx.isopenmore)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_41, _toDisplayString(_ctx.specialAppoint), 1))
                        : _createCommentVNode("", true),
                      (_ctx.showmore && !_ctx.isopenmore)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 2,
                            onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.changeisopenmore(0))),
                            style: {"width":"16px","height":"16px","transform":"rotate(90deg)","cursor":"pointer","margin-top":"10px"},
                            src: _imports_0,
                            alt: ""
                          }))
                        : _createCommentVNode("", true),
                      (_ctx.showmore && _ctx.isopenmore)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 3,
                            onClick: _cache[23] || (_cache[23] = ($event: any) => (_ctx.changeisopenmore(1))),
                            style: {"width":"16px","height":"16px","transform":"rotate(-90deg)","cursor":"pointer","margin-top":"10px"},
                            src: _imports_0,
                            alt: ""
                          }))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("button", {
                  class: "alert-bottom-btn btn btn-white-blue btn-alone white",
                  onClick: _cache[24] || (_cache[24] = ($event: any) => (_ctx.isManualPopup = false))
                }, " 取消 "),
                _createElementVNode("button", {
                  class: "alert-bottom-btn btn btn-green btn-alone",
                  onClick: _cache[25] || (_cache[25] = 
//@ts-ignore
(...args) => (_ctx.onManualEntry && _ctx.onManualEntry(...args)))
                }, " 提交审核 ")
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.isFormError,
        "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.isFormError) = $event)),
        width: "450px",
        title: "名单格式错误人员",
        center: "",
        onClose: _ctx.onFormError
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_43, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formErrorList, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "row row-uninsured",
                key: index
              }, [
                _createElementVNode("div", null, "姓名：" + _toDisplayString(item.name) + " 身份证：(" + _toDisplayString(item.code) + ")", 1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "onClose"]),
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.isUninsuredPopup,
        "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.isUninsuredPopup) = $event)),
        width: "450px",
        title: "未投保人员",
        center: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_44, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uninsuredList, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "row row-uninsured",
                key: index
              }, " 姓名：" + _toDisplayString(item.userName) + " 身份证：（" + _toDisplayString(item.idCardNo.replace(
                            /(.{3})(?:\d+)(.{4})$/,
                            '$1*********$2'
                        )) + "） ", 1))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_ctx.isRemindPopup)
        ? (_openBlock(), _createBlock(_component_alert_box, {
            key: 3,
            class: "remind-popup",
            "data-Title": "确认提交",
            onClose: _cache[32] || (_cache[32] = ($event: any) => (_ctx.isRemindPopup = false))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("div", _hoisted_46, [
                  _cache[64] || (_cache[64] = _createElementVNode("div", { class: "remind-i-title" }, " 保险： ", -1)),
                  _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.tioInfo.insurCompanyPlanName), 1)
                ]),
                _createElementVNode("div", _hoisted_48, [
                  _cache[65] || (_cache[65] = _createElementVNode("div", { class: "remind-i-title" }, " 状态： ", -1)),
                  _createElementVNode("div", {
                    class: _normalizeClass([
                        'operation-type-box',
                        _ctx.insurance !== 'add'
                            ? 'operation-type-box-active'
                            : ''
                    ])
                  }, [
                    (_ctx.insurance === 'add')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_49))
                      : _createCommentVNode("", true),
                    (_ctx.insurance !== 'add')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_50))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(_ctx.insurance !== 'add' ? '减保' : '加保'), 1)
                  ], 2)
                ]),
                _createElementVNode("div", _hoisted_51, [
                  _cache[66] || (_cache[66] = _createElementVNode("div", { class: "remind-i-title" }, " 人数： ", -1)),
                  _createElementVNode("div", _hoisted_52, _toDisplayString(_ctx.insurance === 'reduce'
                                ? _ctx.addList.filter(item => !item.errMsg).length
                                : _ctx.addList.length) + "人 ", 1)
                ]),
                _createElementVNode("div", _hoisted_53, [
                  _cache[67] || (_cache[67] = _createElementVNode("div", { class: "remind-i-title" }, " 日期： ", -1)),
                  _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.date ? _ctx.date : '请先选择时间') + " " + _toDisplayString(_ctx.insurance === 'add' ? '生效' : '失效'), 1)
                ]),
                _createElementVNode("div", _hoisted_55, [
                  _createElementVNode("button", {
                    class: "alert-bottom-btn btn btn-white-blue btn-alone white",
                    onClick: _cache[29] || (_cache[29] = ($event: any) => (_ctx.isRemindPopup = false))
                  }, " 取消 "),
                  (_ctx.insurance === 'add')
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "alert-bottom-btn btn btn-green btn-alone",
                        onClick: _cache[30] || (_cache[30] = 
//@ts-ignore
(...args) => (_ctx.insureAdd && _ctx.insureAdd(...args)))
                      }, " 提交 "))
                    : _createCommentVNode("", true),
                  (_ctx.insurance !== 'add')
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        class: "alert-bottom-btn btn btn-green btn-alone",
                        onClick: _cache[31] || (_cache[31] = 
//@ts-ignore
(...args) => (_ctx.insureReduce && _ctx.insureReduce(...args)))
                      }, " 提交 "))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isSuccessPopup)
        ? (_openBlock(), _createBlock(_component_alert_box, {
            key: 4,
            class: "success-popup",
            "data-Title": "操作成功",
            onClose: _cache[34] || (_cache[34] = ($event: any) => (_ctx.isSuccessPopup = false))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_56, [
                _cache[68] || (_cache[68] = _createElementVNode("img", {
                  src: _imports_3,
                  alt: ""
                }, null, -1)),
                _cache[69] || (_cache[69] = _createElementVNode("div", { class: "title" }, " 操作成功 ", -1)),
                _createElementVNode("div", _hoisted_57, _toDisplayString(_ctx.insurance === 'add' ? '加保' : '减保') + "人数" + _toDisplayString(_ctx.insurance === 'add'
                            ? _ctx.addList.length
                            : _ctx.addList.filter(item => !item.errMsg).length) + "人 ", 1),
                _createElementVNode("button", {
                  class: "btn",
                  style: {"marginLeft":"0px"},
                  onClick: _cache[33] || (_cache[33] = ($event: any) => (
                    _ctx.$router.push({
                        path: '/long-insure/detail/' + _ctx.$route.params.id
                    })
                    ))
                }, " 确定 ")
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.enterErrorList?.length > 0)
        ? (_openBlock(), _createBlock(_component_alert_box, {
            key: 5,
            class: "checkout",
            onClose: _cache[36] || (_cache[36] = ($event: any) => (_ctx.close()))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_58, [
                _cache[70] || (_cache[70] = _createElementVNode("div", { class: "manipulate-mistake" }, " 操作错误 ", -1)),
                _createVNode(_component_el_alert, {
                  title: `以下人员身份信息错误，请修改后重新上传`,
                  type: "error",
                  center: "",
                  "show-icon": ""
                }),
                _createElementVNode("div", _hoisted_59, [
                  _createVNode(_component_el_table, {
                    data: _ctx.enterErrorList,
                    "header-row-style": { color: 'black' },
                    "row-style": { border: 'none' },
                    style: {"width":"100%"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_table_column, {
                        label: "行号",
                        prop: "index"
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "userName",
                        label: "姓名"
                      }),
                      _createVNode(_component_el_table_column, {
                        prop: "idCardNo",
                        label: "身份证号"
                      }),
                      _createVNode(_component_el_table_column, { label: "错误信息" }, {
                        default: _withCtx((scope) => [
                          _createElementVNode("p", null, _toDisplayString(scope.row.msg), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["data"])
                ]),
                _createElementVNode("div", _hoisted_60, [
                  _createElementVNode("button", {
                    class: "btn obtain",
                    onClick: _cache[35] || (_cache[35] = ($event: any) => (_ctx.close()))
                  }, " 知道了 ")
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isRenew)
        ? (_openBlock(), _createBlock(_component_alert_box, {
            key: 6,
            class: "success-popup",
            onClose: _cache[39] || (_cache[39] = ($event: any) => (_ctx.isRenew = false))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_61, [
                _createElementVNode("div", _hoisted_62, [
                  _createElementVNode("div", _hoisted_63, [
                    _cache[71] || (_cache[71] = _createTextVNode(" 本次投保金额 ")),
                    _createElementVNode("text", null, _toDisplayString(_ctx.testingInsureInfo.needCostFeeMonth), 1),
                    _cache[72] || (_cache[72] = _createTextVNode(" 元 "))
                  ]),
                  _createElementVNode("div", _hoisted_64, [
                    (_ctx.testingInsureInfo.price)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                          _cache[73] || (_cache[73] = _createTextVNode(" 3类月单单价 ")),
                          _createElementVNode("text", _hoisted_66, _toDisplayString(_ctx.testingInsureInfo.price), 1),
                          _cache[74] || (_cache[74] = _createTextVNode(" 元 "))
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.testingInsureInfo.priceFour)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                          _cache[75] || (_cache[75] = _createTextVNode(" 4类月单单价 ")),
                          _createElementVNode("text", _hoisted_68, _toDisplayString(_ctx.testingInsureInfo.priceFour), 1),
                          _cache[76] || (_cache[76] = _createTextVNode(" 元 "))
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.testingInsureInfo.priceFive)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
                          _cache[77] || (_cache[77] = _createTextVNode(" 5类月单单价 ")),
                          _createElementVNode("text", _hoisted_70, _toDisplayString(_ctx.testingInsureInfo.priceFive), 1),
                          _cache[78] || (_cache[78] = _createTextVNode(" 元 "))
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_71, [
                      _cache[79] || (_cache[79] = _createTextVNode(" 本次投保 ")),
                      _createElementVNode("text", _hoisted_72, _toDisplayString(_ctx.testingInsureInfo.needCreateCount), 1),
                      _cache[80] || (_cache[80] = _createTextVNode(" 人 "))
                    ]),
                    _createElementVNode("div", _hoisted_73, [
                      _cache[81] || (_cache[81] = _createTextVNode(" 抵扣人数 ")),
                      _createElementVNode("text", _hoisted_74, _toDisplayString(_ctx.testingInsureInfo.deductionCount), 1),
                      _cache[82] || (_cache[82] = _createTextVNode(" 人 "))
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_75, [
                  _createElementVNode("button", {
                    class: "btn btn-white-blue btn-white-blue-active",
                    onClick: _cache[37] || (_cache[37] = ($event: any) => (_ctx.isRenew = false))
                  }, " 取消 "),
                  _createElementVNode("button", {
                    class: "btn btn-green btn-green-active",
                    onClick: _cache[38] || (_cache[38] = 
//@ts-ignore
(...args) => (_ctx.insureAdd && _ctx.insureAdd(...args)))
                  }, " 确定投保 ")
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isRenewAmount)
        ? (_openBlock(), _createBlock(_component_alert_box, {
            key: 7,
            class: "success-popup",
            onClose: _cache[41] || (_cache[41] = ($event: any) => (_ctx.isRenewAmount = false))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_76, [
                _createElementVNode("div", _hoisted_77, [
                  _cache[91] || (_cache[91] = _createElementVNode("div", { class: "title" }, "本次投保账户余额不足", -1)),
                  _createElementVNode("div", _hoisted_78, [
                    _cache[89] || (_cache[89] = _createElementVNode("div", { class: "name" }, "因当前待生效", -1)),
                    _createElementVNode("div", _hoisted_79, [
                      _cache[83] || (_cache[83] = _createTextVNode(" 保单金额为 ")),
                      _createElementVNode("text", _hoisted_80, _toDisplayString(_ctx.testingInsureInfo.tomorrowNeedCostFee), 1),
                      _cache[84] || (_cache[84] = _createTextVNode(" 元 "))
                    ]),
                    _createElementVNode("div", _hoisted_81, [
                      _cache[85] || (_cache[85] = _createTextVNode(" 当前余额为 ")),
                      _createElementVNode("text", _hoisted_82, _toDisplayString(_ctx.testingInsureInfo.currentBalance), 1),
                      _cache[86] || (_cache[86] = _createTextVNode(" 元 "))
                    ]),
                    _createElementVNode("div", _hoisted_83, [
                      _cache[87] || (_cache[87] = _createTextVNode(" 本次投保金额 ")),
                      _createElementVNode("text", _hoisted_84, _toDisplayString(_ctx.testingInsureInfo.willEnsureCostFee), 1),
                      _cache[88] || (_cache[88] = _createTextVNode(" 元 "))
                    ]),
                    _cache[90] || (_cache[90] = _createElementVNode("div", { class: "name" }, " 导致本次投保保费不足。请充值后重试 ", -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_85, [
                  _createElementVNode("button", {
                    class: "btn btn-green",
                    onClick: _cache[40] || (_cache[40] = ($event: any) => (_ctx.isRenewAmount = false))
                  }, " 我知道啦 ")
                ])
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_86, [
      _createElementVNode("div", _hoisted_87, [
        _createElementVNode("div", _hoisted_88, [
          (_ctx.selectionList.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
                _cache[92] || (_cache[92] = _createTextVNode(" 已选择")),
                _createElementVNode("span", _hoisted_90, _toDisplayString(_ctx.selectionList.length), 1),
                _cache[93] || (_cache[93] = _createTextVNode("项 "))
              ]))
            : _createCommentVNode("", true),
          (_ctx.selectionList.length > 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "delete point",
                onClick: _cache[42] || (_cache[42] = 
//@ts-ignore
(...args) => (_ctx.batchDelete && _ctx.batchDelete(...args)))
              }, " 删除选中 "))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_91, [
          (_ctx.selectionList.length > 0 && _ctx.insurance === 'add')
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "btn btn-white-blue",
                onClick: _cache[43] || (_cache[43] = ($event: any) => (_ctx.onDisplayIsEditPopup('batch')))
              }, " 设置工种 "))
            : _createCommentVNode("", true),
          (_ctx.insureRenewal)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "btn btn-red",
                style: {"background":"#1989FA","margin":"20px 0 20px 10px"},
                onClick: _cache[44] || (_cache[44] = 
//@ts-ignore
(...args) => (_ctx.onTestingInsure && _ctx.onTestingInsure(...args)))
              }, " 一键续保 "))
            : _createCommentVNode("", true),
          (_ctx.insurance === 'add' && !_ctx.insureRenewal)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                class: "btn btn-red",
                style: {"background":"#1989FA","margin":"20px 0 20px 10px"},
                onClick: _cache[45] || (_cache[45] = ($event: any) => (_ctx.isRemindPopup = true))
              }, " 确认加保 "))
            : _createCommentVNode("", true),
          (_ctx.insurance === 'reduce')
            ? (_openBlock(), _createElementBlock("button", {
                key: 3,
                class: "btn btn-red",
                style: {"background":"#1989FA","margin":"20px 0"},
                onClick: _cache[46] || (_cache[46] = ($event: any) => (_ctx.isRemindPopup = true))
              }, " 确认减保 "))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}