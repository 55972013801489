
import { defineComponent } from 'vue'
import { rechargeRecordExport } from '@/api/export'
import { insureRechargeRecord, elesignRechargeRecord, getPayList, ResultRechargeRecordDto } from '@/api/recharge-record'
import store from '@/store/index'
import axios from 'axios'
import { downLoadFile } from '../utils/util'
import rechargeTable from '@/components/seat/rechargeTable.vue';
import orderInfo from '@/components/seat/order-info.vue';
import { getRechargeList } from '@/api/recharge-record'

export default defineComponent({
    name: 'RechargeRecord',
    components: {
        rechargeTable,
        orderInfo
    },
    data() {
        return {
            isThatkindType: 'pay',
            rechargeTime: '',
            payTime: '',
            insuranceTime: '',
            elesignTime: '',
            longInsuranceTime: '',

            insuranceList: [] as ResultRechargeRecordDto[], // 保险列表
            insuranceTotal: 0 as number,
            insurancePageNum: 1,
            insurancePageSize: 10,

            longInsuranceList: [] as ResultRechargeRecordDto[], // 长期保列表
            longInsuranceTotal: 0 as number,
            longInsurancePageNum: 1,
            longInsurancePageSize: 10,

            elesignList: [] as ResultRechargeRecordDto[], // 电子签
            elesignTotal: 0,
            elesignPageNum: 1,
            elesignPageSize: 10,

            payList: [] as ResultRechargeRecordDto[], // 发薪
            payTotal: 0,
            payPageNum: 1,
            payPageSize: 10,
            tableData: [] as any,
            pageInfo: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
        }
    },
    computed: {
        isChangeTab() {
            return store.state.changeRechargeTab
        }
    },
    watch: {
        isChangeTab(newval) {
            if (newval) {
                const tab = newval.split('-')[0]
                this.onTabs({
                    props: {
                        name: tab
                    }
                })
            }
        }
    },
    async created() {
        this.getInsureRechargeRecord()
        this.getLongInsureRechargeRecord()
        this.getElesignRechargeRecord()
        this.getPayList()
        if(!this?.$?.appContext?.app?.config?.globalProperties?.$hideXiWei) {
            this.getGroupAccountList()
        }
    },
    beforeRouteEnter(to, from, next) {
        next((vm: any) => {
            if (to.query?.tab) {
                vm.onTabs({
                    props: {
                        name: vm.$route.query?.tab as string
                    }
                })
            }
        })
    },
    methods: {
        // 投保列表
        async getInsureRechargeRecord() {
            const result = await insureRechargeRecord({
                pageNum: this.insurancePageNum,
                pageSize: this.insurancePageSize,
                beginCreateTime: this.rechargeTime ? this.rechargeTime[0] : '',
                endCreateTime: this.rechargeTime ? this.rechargeTime[1] : '',
                exportType: 'insurance'
            })
            this.insuranceList = result.list
            this.insuranceTotal = result.total
        },
        insureSizeChange() {
            this.insurancePageNum = 1
            this.getInsureRechargeRecord()
        },
        // 长期保列表
        async getLongInsureRechargeRecord() {
            const result = await insureRechargeRecord({
                pageNum: this.longInsurancePageNum,
                pageSize: this.longInsurancePageSize,
                beginCreateTime: this.rechargeTime ? this.rechargeTime[0] : '',
                endCreateTime: this.rechargeTime ? this.rechargeTime[1] : '',
                exportType: 'period'
            })
            this.longInsuranceList = result.list
            this.longInsuranceTotal = result.total
        },
        longInsureSizeChange() {
            this.longInsurancePageNum = 1
            this.getLongInsureRechargeRecord()
        },
        // 电子签列表
        async getElesignRechargeRecord() {
            const result = await elesignRechargeRecord({
                pageNum: this.elesignPageNum,
                pageSize: this.elesignPageSize,
                beginCreateTime: this.rechargeTime ? this.rechargeTime[0] : '',
                endCreateTime: this.rechargeTime ? this.rechargeTime[1] : ''
            })
            this.elesignList = result.list
            this.elesignTotal = result.total
        },
        elesignSizeChange() {
            this.elesignPageNum = 1
            this.getElesignRechargeRecord()
        },
        // 发薪记录
        async getPayList() {
            const result = await getPayList({
                pageNum: this.payPageNum,
                pageSize: this.payPageSize,
                beginCreateTime: this.rechargeTime ? this.rechargeTime[0] : '',
                endCreateTime: this.rechargeTime ? this.rechargeTime[1] : ''
            })
            this.payList = result.list
            this.payTotal = result.total
        },
        paySizeChange() {
            this.payPageNum = 1
            this.getPayList()
        },
        async onExport() {
            const result = await rechargeRecordExport({
                exportType: this.isThatkindType + '',
                beginCreateTime: this.rechargeTime ? this.rechargeTime[0] : '',
                endCreateTime: this.rechargeTime ? this.rechargeTime[1] : ''
            }, {
                loading: true
            })
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
            // window.location.href =
            //     process.env.VUE_APP_BASE_URL +
            //         '/common/download?delete=true&fileName=' + result

            // this.$message.success('导出成功！')
        },
        onTabs(tab: { props: { name: string } }) {
            this.isThatkindType = tab.props.name
            console.log(tab, 'this.isThatkindType')
            this.judgeTime()
        },
        judgeTime() {
            if (this.isThatkindType === 'pay' && this.payTime !== this.rechargeTime) {
                this.paySizeChange()
                this.payTime = this.rechargeTime
            } else if (this.isThatkindType === 'insurance' && this.insuranceTime !== this.rechargeTime) {
                this.insureSizeChange()
                this.insuranceTime = this.rechargeTime
            } else if (this.isThatkindType === 'sign' && this.elesignTime !== this.rechargeTime) {
                this.elesignSizeChange()
                this.elesignTime = this.rechargeTime
            } else if (this.isThatkindType === 'period' && this.longInsuranceTime !== this.rechargeTime) {
                this.getLongInsureRechargeRecord()
                this.longInsuranceTime = this.rechargeTime
            }
        },
        // 判断查询
        search() {
            if (this.isThatkindType === 'pay') {
                this.paySizeChange()
            } else if (this.isThatkindType === 'insurance') {
                this.insureSizeChange()
            } else if (this.isThatkindType === 'sign') {
                this.elesignSizeChange()
            } else {
                this.getLongInsureRechargeRecord()
            }
        },
        // 团队账户列表
        getGroupAccountList() {
            getRechargeList({
                pageNum: this.pageInfo.pageNum,
                pageSize: this.pageInfo.pageSize
            }).then(res => {
                this.tableData = res.list || []
                this.pageInfo.total = res.totalCount
            })
        },
        handleRefresh() {
            this.getGroupAccountList()
        },
        handleChangeTab(ev: string) {
            this.onTabs({
                props: {
                    name: ev
                }
            })
        }
    }
})
