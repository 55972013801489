import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/long-insure/renewal.png'
import _imports_1 from '../assets/long-insure/add-btn.png'
import _imports_2 from '../assets/long-insure/reduce-btn.png'
import _imports_3 from '../assets/chart/chart-line-green.png'
import _imports_4 from '@/assets/chart/chart-line-blue.png'
import _imports_5 from '../assets/chart/chart-bar-blue.png'
import _imports_6 from '../assets/long-insure/add-safe.png'
import _imports_7 from '../assets/long-insure/reduce-safe.png'


const _hoisted_1 = { class: "header block" }
const _hoisted_2 = { class: "header-left" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "header-left-info" }
const _hoisted_5 = { class: "left-info-title" }
const _hoisted_6 = {
  key: 0,
  class: "info-title-tips"
}
const _hoisted_7 = { class: "left-info-date" }
const _hoisted_8 = { class: "header-right flex flex-ai-c flex-wrap" }
const _hoisted_9 = { class: "container-info-box" }
const _hoisted_10 = { class: "row container-row-box" }
const _hoisted_11 = { class: "block-active-list" }
const _hoisted_12 = { class: "block block-active" }
const _hoisted_13 = { class: "block-content" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "" }
const _hoisted_16 = { class: "balance" }
const _hoisted_17 = { class: "number" }
const _hoisted_18 = { class: "block block-active" }
const _hoisted_19 = { class: "block-content" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "" }
const _hoisted_22 = { class: "number" }
const _hoisted_23 = { class: "block block-active" }
const _hoisted_24 = { class: "block-content" }
const _hoisted_25 = { class: "row" }
const _hoisted_26 = { class: "" }
const _hoisted_27 = { class: "number" }
const _hoisted_28 = { class: "container-list" }
const _hoisted_29 = { class: "block premium-trend" }
const _hoisted_30 = { class: "block-header" }
const _hoisted_31 = { class: "block-content" }
const _hoisted_32 = { class: "table" }
const _hoisted_33 = {
  key: 0,
  class: "operation-type-img",
  src: _imports_6
}
const _hoisted_34 = {
  key: 1,
  class: "operation-type-img",
  src: _imports_7
}
const _hoisted_35 = { class: "decorate-box" }
const _hoisted_36 = { class: "operation-box" }
const _hoisted_37 = ["onClick"]
const _hoisted_38 = {
  key: 0,
  class: "point"
}
const _hoisted_39 = ["onClick"]
const _hoisted_40 = { class: "block" }
const _hoisted_41 = { class: "block-content" }
const _hoisted_42 = { class: "row" }
const _hoisted_43 = { class: "block plan" }
const _hoisted_44 = { class: "block-header" }
const _hoisted_45 = { class: "staff-list" }
const _hoisted_46 = { class: "dressing-by-screening" }
const _hoisted_47 = { class: "table table-naem" }
const _hoisted_48 = {
  key: 0,
  class: "rovalsState"
}
const _hoisted_49 = {
  key: 1,
  class: "rovalsState"
}
const _hoisted_50 = {
  key: 2,
  class: "rovalsState"
}
const _hoisted_51 = { class: "edit-popup-box" }
const _hoisted_52 = { class: "row" }
const _hoisted_53 = { style: {"width":"100%"} }
const _hoisted_54 = { class: "row" }
const _hoisted_55 = { style: {"width":"100%"} }
const _hoisted_56 = { class: "row-btn" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_export_button = _resolveComponent("export-button")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_tab_capsule = _resolveComponent("tab-capsule")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_alert_box = _resolveComponent("alert-box")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _ctx.tioInfo.insurCompanyLogo,
          alt: "",
          class: "portrait"
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, _toDisplayString(_ctx.tioInfo.insurCompanyPlanName), 1),
            (_ctx.tioInfo.type === 4)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, " 月单 "))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.tioInfo.beginTime) + " 至 " + _toDisplayString(_ctx.tioInfo.endTime || '暂无到期时间'), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_ctx.canInsureRenewal && !_ctx.tioInfo.isEnd)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-white-blue right-btn",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onRenew && _ctx.onRenew(...args)))
            }, _cache[17] || (_cache[17] = [
              _createElementVNode("img", {
                class: "header-right-img",
                src: _imports_0
              }, null, -1),
              _createTextVNode(" 续保 ")
            ])))
          : _createCommentVNode("", true),
        (!_ctx.tioInfo.isEnd)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "btn btn-green right-btn",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInsureAdd && _ctx.onInsureAdd(...args)))
            }, _cache[18] || (_cache[18] = [
              _createElementVNode("img", {
                class: "header-right-img",
                src: _imports_1
              }, null, -1),
              _createTextVNode(" 加保 ")
            ])))
          : _createCommentVNode("", true),
        (!_ctx.tioInfo.isEnd)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "btn btn-red right-btn",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInsureSubtract && _ctx.onInsureSubtract(...args)))
            }, _cache[19] || (_cache[19] = [
              _createElementVNode("img", {
                class: "header-right-img",
                src: _imports_2
              }, null, -1),
              _createTextVNode(" 减保 ")
            ])))
          : _createCommentVNode("", true),
        _createVNode(_component_export_button, {
          class: "mg-l-20",
          onExport: _ctx.handleExport
        }, null, 8, ["onExport"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "block-title" }, "客户管理", -1)),
        _createElementVNode("div", null, [
          _createVNode(_component_el_date_picker, {
            modelValue: _ctx.customerDate,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customerDate) = $event)),
            type: "month",
            placeholder: "选择日期",
            onChange: _ctx.getInsureDetailInfo,
            format: "YYYY/MM",
            "value-format": "YYYY-MM"
          }, null, 8, ["modelValue", "onChange"])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _cache[23] || (_cache[23] = _createElementVNode("div", { class: "block-header" }, [
            _createElementVNode("div", { class: "block-title" }, "总人数"),
            _createElementVNode("div", { class: "block-more" })
          ], -1)),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _cache[21] || (_cache[21] = _createElementVNode("div", { class: "key" }, "人数", -1)),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.tioInfo.insurTotalCount), 1)
                ])
              ]),
              _cache[22] || (_cache[22] = _createElementVNode("img", {
                class: "img-chart",
                src: _imports_3
              }, null, -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _cache[26] || (_cache[26] = _createElementVNode("div", { class: "block-header" }, [
            _createElementVNode("div", { class: "block-title" }, "当前在保人数"),
            _createElementVNode("div", { class: "block-more" })
          ], -1)),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _cache[24] || (_cache[24] = _createElementVNode("div", { class: "key" }, "人数", -1)),
                _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.tioInfo.insurValidCount), 1)
              ]),
              _cache[25] || (_cache[25] = _createElementVNode("img", {
                class: "img-chart",
                src: _imports_4
              }, null, -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _cache[30] || (_cache[30] = _createElementVNode("div", { class: "block-header" }, [
            _createElementVNode("div", { class: "block-title" }, "本月保费"),
            _createElementVNode("div", { class: "block-more" })
          ], -1)),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _cache[27] || (_cache[27] = _createElementVNode("div", { class: "key" }, "金额（元）", -1)),
                _cache[28] || (_cache[28] = _createTextVNode(" ¥")),
                _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.tioInfo.monthAmount), 1)
              ]),
              _cache[29] || (_cache[29] = _createElementVNode("img", {
                class: "img-chart",
                src: _imports_5
              }, null, -1))
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_28, [
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, [
          _cache[31] || (_cache[31] = _createElementVNode("div", { class: "block-title" }, "加减记录", -1)),
          _createElementVNode("div", null, [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.countDate,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.countDate) = $event)),
              type: "date",
              placeholder: "选择日期",
              onChange: _ctx.getInsureDetailAdditionList,
              format: "YYYY/MM/DD",
              "value-format": "YYYY-MM-DD"
            }, null, 8, ["modelValue", "onChange"])
          ])
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _createVNode(_component_el_table, {
              data: _ctx.countList,
              style: {"width":"100%"},
              "header-row-style": { color: '#202536' },
              "row-style": { color: '#5E617D' },
              "header-cell-style": { background: '#F7F8FC' }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, { label: "操作类型" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", {
                      class: _normalizeClass([
                                        'operation-type-box',
                                        scope.row.operationType == 1 || scope.row.operationType == 2
                                            ? 'operation-type-box-active'
                                            : ''
                                    ])
                    }, [
                      (scope.row.operationType == 0)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_33))
                        : _createCommentVNode("", true),
                      (scope.row.operationType == 1 || scope.row.operationType == 2)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_34))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(scope.row.operationType == 1 ? '减保' : scope.row.operationType == 2 ? '撤保' : '加保'), 1)
                    ], 2)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  label: "人数",
                  prop: "total"
                }),
                _createVNode(_component_el_table_column, {
                  label: "生效日期",
                  prop: "insurStartDate"
                }),
                _createVNode(_component_el_table_column, {
                  width: "200px",
                  prop: "createTime",
                  label: "录入时间"
                }),
                _createVNode(_component_el_table_column, {
                  label: "录入人",
                  prop: "managerName"
                }),
                _createVNode(_component_el_table_column, { label: "状态" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_35, [
                      _createElementVNode("div", {
                        class: "decorate",
                        style: _normalizeStyle({
                                            background:
                                                scope.row.isSuccess == 1
                                                    ? '#00D679 '
                                                    : scope.row.isSuccess ==
                                                        0
                                                        ? '#FF7E68'
                                                        : ''
                                        })
                      }, null, 4),
                      _createElementVNode("p", null, _toDisplayString(scope.row.isSuccess
                                                    ? '成功'
                                                    : '失败'), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { label: "操作" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("p", {
                        style: {"color":"#1989FA"},
                        class: "point",
                        onClick: ($event: any) => (
                                            _ctx.getInsureDetailAdditionExport(
                                                scope.row.id
                                            )
                                            )
                      }, " 导出名单 ", 8, _hoisted_37),
                      (scope.row.isAllowRevoke && scope.row.managerId != 1)
                        ? (_openBlock(), _createElementBlock("text", _hoisted_38, "/"))
                        : _createCommentVNode("", true),
                      (scope.row.isAllowRevoke && scope.row.managerId != 1)
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 1,
                            style: {"color":"#1989FA"},
                            class: "point",
                            onClick: ($event: any) => (_ctx.onLongTermRevoke(scope.row.id))
                          }, " 撤回", 8, _hoisted_39))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data"]),
            _createVNode(_component_el_pagination, {
              class: "pagination",
              currentPage: _ctx.countPageNum,
              "onUpdate:currentPage": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.countPageNum) = $event)),
              "page-size": _ctx.countPageSize,
              "onUpdate:pageSize": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.countPageSize) = $event)),
              "page-sizes": [10, 30, 50, 100],
              layout: "total, prev, pager, next, jumper",
              total: _ctx.countTotal,
              onSizeChange: _ctx.getInsureDetailAdditionList,
              onCurrentChange: _ctx.getInsureDetailAdditionList
            }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_40, [
        _cache[34] || (_cache[34] = _createElementVNode("div", { class: "block-header" }, [
          _createElementVNode("div", { class: "block-title" }, "人员统计")
        ], -1)),
        _createElementVNode("div", _hoisted_41, [
          _createElementVNode("div", _hoisted_42, [
            _cache[32] || (_cache[32] = _createElementVNode("div", null, null, -1)),
            _createVNode(_component_tab_capsule, {
              class: "tab-cap",
              active: _ctx.statisticsTab,
              list: ['工种', '被派遣单位'],
              onTabchange: _ctx.onStatisticsTab
            }, null, 8, ["active", "onTabchange"])
          ]),
          _cache[33] || (_cache[33] = _createElementVNode("div", { id: "echarts" }, null, -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_43, [
      _createElementVNode("div", _hoisted_44, [
        _cache[35] || (_cache[35] = _createElementVNode("div", { class: "block-title" }, "人员名单", -1)),
        _createElementVNode("div", _hoisted_45, [
          _createElementVNode("div", _hoisted_46, [
            _createVNode(_component_el_select, {
              modelValue: _ctx.payType,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.payType) = $event)),
              filterable: "",
              placeholder: "筛选状态",
              class: "screen-i",
              onChange: _ctx.handleCurrentChange
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.payTypeList, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    label: item,
                    value: item,
                    key: index
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item), 1)
                    ]),
                    _: 2
                  }, 1032, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.personneDate,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.personneDate) = $event)),
              type: "date",
              placeholder: "选择日期",
              onChange: _ctx.getInsureDetailPersonnelList,
              format: "YYYY/MM/DD",
              "value-format": "YYYY-MM-DD"
            }, null, 8, ["modelValue", "onChange"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_47, [
        _createVNode(_component_el_table, {
          data: _ctx.personnelList,
          style: {"width":"100%"},
          "header-row-style": { color: '#202536' },
          "row-style": { color: '#5E617D' },
          "header-cell-style": { background: '#F7F8FC' }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "userName",
              label: "姓名"
            }),
            _createVNode(_component_el_table_column, { label: "身份证号" }, {
              default: _withCtx((scope) => [
                _createElementVNode("p", null, _toDisplayString(scope.row.encryptionIdCardNo), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              label: "被派遣单位",
              prop: "serveCompanyName"
            }),
            _createVNode(_component_el_table_column, {
              width: "240px",
              prop: "createTime",
              label: "录入时间"
            }),
            _createVNode(_component_el_table_column, {
              label: "所属工种",
              prop: "occupationName"
            }),
            _createVNode(_component_el_table_column, {
              label: "保险起期",
              prop: "insurStartTime"
            }),
            _createVNode(_component_el_table_column, {
              label: "保险止期",
              prop: "insurEndTime"
            }),
            _createVNode(_component_el_table_column, {
              label: "状态",
              class: "dot",
              "is-dot": ""
            }, {
              default: _withCtx((scoped) => [
                (scoped.row.policyStatus == '0')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_48, _cache[36] || (_cache[36] = [
                      _createElementVNode("em", { class: "sub" }, null, -1),
                      _createTextVNode("待生效 ")
                    ])))
                  : _createCommentVNode("", true),
                (scoped.row.policyStatus == '1')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_49, _cache[37] || (_cache[37] = [
                      _createElementVNode("em", { class: "adopt" }, null, -1),
                      _createTextVNode("保障中 ")
                    ])))
                  : _createCommentVNode("", true),
                (scoped.row.policyStatus == '2')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_50, _cache[38] || (_cache[38] = [
                      _createElementVNode("em", { class: "stay" }, null, -1),
                      _createTextVNode("不在保 ")
                    ])))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"]),
        _createVNode(_component_el_pagination, {
          class: "pagination",
          currentPage: _ctx.personnelPageNum,
          "onUpdate:currentPage": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.personnelPageNum) = $event)),
          "page-size": _ctx.personnelPageSize,
          "onUpdate:pageSize": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.personnelPageSize) = $event)),
          layout: "total, prev, pager, next, jumper",
          total: _ctx.personnelTotal,
          onSizeChange: _ctx.getInsureDetailPersonnelList,
          onCurrentChange: _ctx.getInsureDetailPersonnelList
        }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
      ])
    ]),
    (_ctx.isExportAllPopup)
      ? (_openBlock(), _createBlock(_component_alert_box, {
          key: 0,
          class: "alert-box",
          "data-Title": "导出账单",
          onClose: _cache[16] || (_cache[16] = ($event: any) => (_ctx.isExportAllPopup = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_51, [
              _createElementVNode("div", _hoisted_52, [
                _cache[41] || (_cache[41] = _createElementVNode("div", { class: "name" }, [
                  _createElementVNode("span", { class: "important" }, "*"),
                  _createTextVNode(" 格式 ")
                ], -1)),
                _createElementVNode("div", _hoisted_53, [
                  _createVNode(_component_el_radio, {
                    modelValue: _ctx.exportType,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.exportType) = $event)),
                    label: "1",
                    size: "large"
                  }, {
                    default: _withCtx(() => _cache[39] || (_cache[39] = [
                      _createTextVNode("按天 ")
                    ])),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_el_radio, {
                    modelValue: _ctx.exportType,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.exportType) = $event)),
                    label: "0",
                    size: "large"
                  }, {
                    default: _withCtx(() => _cache[40] || (_cache[40] = [
                      _createTextVNode("按人员 ")
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_54, [
                _cache[42] || (_cache[42] = _createElementVNode("div", { class: "name" }, [
                  _createElementVNode("span", { class: "important" }, "*"),
                  _createTextVNode(" 选择月份 ")
                ], -1)),
                _createElementVNode("div", _hoisted_55, [
                  _createVNode(_component_el_date_picker, {
                    modelValue: _ctx.exportDate,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.exportDate) = $event)),
                    type: "month",
                    placeholder: "选择月份",
                    format: "YYYY-MM",
                    "value-format": "YYYY-MM"
                  }, null, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_56, [
                _createElementVNode("button", {
                  class: "btn btn-white-blue",
                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.isExportAllPopup = false))
                }, " 取消 "),
                _createElementVNode("button", {
                  class: "btn btn-red",
                  style: {"background":"#1989FA"},
                  onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.exportWhole && _ctx.exportWhole(...args)))
                }, " 确认导出 ")
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}