import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  class: "upload-box",
  style: {"width":"100%"}
}
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { style: {"width":"100%"} }
const _hoisted_5 = { class: "dialog-btn-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.showDialog,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDialog) = $event)),
      title: _ctx.titleName,
      width: "642px",
      "destroy-on-close": "",
      center: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "name" }, [
            _createElementVNode("span", {
              class: "important",
              style: {"opacity":"-0.9"}
            }, "*"),
            _createTextVNode(" 上传视频 ")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_upload, {
              class: "upload-demo",
              action: _ctx.uploadAction,
              headers: { Authorization: _ctx.Authorization },
              "on-success": _ctx.fileSuccess,
              "on-error": _ctx.fileEerror,
              "show-file-list": true,
              "before-upload": _ctx.fileBeforeUpload,
              accept: "image/jpeg,image/image/png,video/*"
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("button", {
                  class: "btn-white-blue",
                  style: {"width":"130px","background":"#1989FA","color":"#fff"}
                }, [
                  _createElementVNode("i", { class: "el-icon el-icon-camera" }),
                  _createTextVNode("选择视频文件 ")
                ], -1)
              ])),
              _: 1
            }, 8, ["action", "headers", "on-success", "on-error", "before-upload"]),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "upload-introduce" }, " 视频时间10s-60s 视频大小不超50M ", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "name" }, [
            _createElementVNode("span", { class: "important" }, "*"),
            _createTextVNode(" 被派遣单位 ")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_el_select, {
              modelValue: _ctx.companyId,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.companyId) = $event)),
              filterable: "",
              disabled: _ctx.disabled,
              placeholder: "请选择派遣单位"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyList, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.id,
                    label: item.serveCompanyName,
                    value: item.id
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "disabled"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", {
            class: "btn-white",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog = false)),
            style: {"marginRight":"20px"}
          }, "取消"),
          _createElementVNode("button", {
            class: "btn",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args))),
            style: {"marginLength":"20px"}
          }, "提交审核")
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "title"])
  ]))
}