
import { defineComponent } from 'vue'
import { insureCaseList } from '@/api/insure-deduction'
import { BillDetailDto, BillDto } from '@/api/bill'
export default defineComponent({
    name: 'InsureTeamReport',
    components: {},
    data() {
        return {
            stateCode: '',
            pageSize: 10,
            pageNum: 1,
            total: 0,
            tableData: [] as BillDetailDto[],
            searchText: '',
            searchValue: '',
            selects: [] as string[]
        }
    },
    created() {
        this.getPayrollList()
    },
    methods: {
        async getPayrollList() {
            const result = await insureCaseList({
                reportStatus: this.stateCode,
                searchText: this.searchValue,
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                insureCompanyType: 'insure_type_tyrb'
            })
            this.total = result.total
            this.tableData = result.rows
        },
        handleSizeChange() {
            this.pageNum = 1
            this.getPayrollList()
        },
        search() {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.getPayrollList()
        },
        onReset() {
            this.stateCode = ''
            this.searchText = ''
            this.search()
        },
        handleSelectionChange(selects: { id: string }[]) {
            this.selects = selects.map(item => item.id)
        }
    }
})
