<template>
    <el-drawer ref="drawRef" class="reset" v-model="dialogVisible" :title="'上传资料'" :destroy-on-close="true"
        :before-close="handleClose" :size="570" :close-on-press-escape="false" :close-on-click-modal="false">
        <!-- <div class="flex flex-jc-sb border-bottom pd-b-10 mg-b-20">
            <div class="fs-16 bold"></div>
        </div> -->
        <div class="container">
            <el-form ref="formRef" :model="formData" :rules="rules" label-position="top" class="">
                <div class="grid">
                    <el-form-item v-for="(i, index) in formData" :key="index" :label="i.label">
                        <template #label>
                            <div class="header fs-14 flex flex-ai-c fc-main">
                                <span>
                                    {{ i.name }}（{{ i.valueUrl.length }}/{{ i.limitSize }}）
                                </span>
                                <span v-if="i.required" class="fc-red mg-l-5 mg-r-5">*</span>
                                <span class="hint fs-14 fc-lightgrey">{{ i.detail }}</span>
                            </div>
                        </template>
                        <div class="reset-card">
                            <uploadImage can-compress :file-url="i.valueUrl" prop-name="fileUrl" :limit="i.limitSize"
                                :limit-size="10" remove-confirm @success="handleSuccess($event, i)"
                                @remove="handleRemove($event, i)" :preview-list="previewList">
                                <template #button>
                                    <div class="flex flex-ai-c absolute">
                                        <img src="../assets/share.png" class="icon-share" alt="">
                                        <span class="fc-primary">上传附件</span>
                                        <span class="fs-14 fc-hintgrey mg-l-10">大小限制10MB，支持png，jpeg，jpg</span>
                                    </div>
                                </template>
                            </uploadImage>
                        </div>
                    </el-form-item>
                </div>
            </el-form>
        </div>
        <div class="flex flex-jc-c">
            <el-button type="primary" plain @click="doExport">导出</el-button>
        </div>
    </el-drawer>
</template>

<script>
import { defineComponent } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus';
import { getMaterialsLong, addMaterialsLong, removeMaterialsLong, exportImagesLong, getMaterialsDay, addMaterialsDay, removeMaterialsDay, exportImagesDay } from '../api/add-material.ts';
import uploadImage from './upload-image.vue'

export default defineComponent({
    name: 'AddMaterial',
    props: {
        show: {
            type: Boolean,
            default: false
        },
        insureType: {
            type: String,
            default: 'long'
        },
        curItem: {
            type: Object
        },
    },
    components: {
        uploadImage
    },
    watch: {
        show(newval) {
            this.dialogVisible = newval
            if(newval) {
                this.$nextTick(() => {
                    this.getData()
                })
            }
        },
        dialogVisible(newval) {
            this.$emit('update:show', newval)
        },
        curItem: {
            handler(newval) {
                // console.log(33333, newval);
                // if (newval && Object.keys(newval).length) {
                //     this.getData()
                // }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            dialogVisible: false,
            formData: {},
            rules: [],
            axios: {
                long: {
                    getMaterial: getMaterialsLong,
                    addMaterial: addMaterialsLong,
                    removeMaterial: removeMaterialsLong,
                    export: exportImagesLong
                },
                day: {
                    getMaterial: getMaterialsDay,
                    addMaterial: addMaterialsDay,
                    removeMaterial: removeMaterialsDay,
                    export: exportImagesDay
                }
            },
            previewList: []
        }
    },
    created() {
    },
    methods: {
        getData() {
            this.axios[this.insureType].getMaterial({
                reportCaseId: this.curItem.id,
                insureCompany: this.curItem.insurCompany || this.curItem.insureCompanyType
            }).then(res => {
                res.forEach(i => {
                    this.formData[i.type] = {
                        ...i,
                        valueUrl: i.files || [],
                        required: !!i.isRequired
                    }
                });
                const list = res.map(i => i.files).flat()
                this.previewList = list.map(i => i.fileUrl)
                console.log(5678, this.previewList);
            })
        },
        doExport() {
            this.axios[this.insureType].export({
                reportCaseId: this.curItem.id,
                insureCompany: this.curItem.insurCompany || this.curItem.insureCompanyType
            }).then((blob) => {
                const blobs = new Blob([blob], {
                    type: 'application/zip'
                })
                const a = document.createElement('a')
                const URL = window.URL || winodw.webketURL
                a.href = URL.createObjectURL(blobs)
                a.download = this.$store.state.blobFileName
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                ElMessage.success({
                    message: '文件下载中，请稍作等待'
                })
            })
        },

        handleClose(done) {
            const values = Object.values(this.formData)
            const requireList = values.filter(i => i.required)

            if (requireList.some(i => !i.valueUrl?.length)) {
                ElMessageBox.confirm(
                    '您还有必填的资料未上传，是否确认退出？',
                    '提示',
                    {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }
                )
                    .then(() => {
                        done()
                    })
                    .catch(() => {
                    })
            } else {
                done()
            }
        },
        handleSuccess(base64, i) {
            this.axios[this.insureType].addMaterial({
                reportCaseId: this.curItem.id,
                categoryType: i.type,
                file: base64,
                companyId: this.curItem.companyId
            }).then(res => {
                i.valueUrl.push({
                    fileId: res.fileId,
                    fileUrl: res.fileUrl
                })
            })
        },
        handleRemove(file, i) {
            this.axios[this.insureType].removeMaterial(file.fileId).then(res => {
                const index = i.valueUrl.findIndex(k => k.fileId === file.fileId)
                if (index !== -1) {
                    i.valueUrl.splice(index, 1)
                }
                ElMessage.success({
                    message: '删除成功'
                })
            })
        }

    }
})
</script>

<style lang="stylus" scoped>
.header {
    &::before {
        content: '';
        border-left: 4px solid #1989FA;
        height: 14px;
        margin-right: 5px;
    }
}
:deep() .reset .el-input__wrapper {
    display: flex;
}

:deep() .el-upload--picture-card {
    justify-content: start;
    background: #fff;
}

:deep() .el-upload-list__item {
    width: 72px;
    height: 72px;
}

:deep() .reset .el-drawer__header {
    margin-bottom: 0;
}
.container {
    height: calc(100vh - 150px);
    overflow: auto;
}
.absolute {
    margin-top: -65px;
}
.fc-primary {
    color: #1989FA;
}
.fc-lightgrey {
    color: #9497B1;
}
.fc-hintgrey {
    color: #aaa;
}
:deep() .el-form-item {
    margin-bottom: 0;
}
:deep() .el-drawer__header {
    margin-bottom: 0 !important;
}
.icon-share {
    width: 14px;
    height: 14px;
}
</style>