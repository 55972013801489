import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "block" }
const _hoisted_3 = { class: "block-box" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  class: "upload-box",
  style: {"width":"100%"}
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { style: {"width":"100%"} }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { style: {"width":"100%"} }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { style: {"width":"100%"} }
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "row" }
const _hoisted_21 = { class: "row row-alone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_cascader = _resolveComponent("el-cascader")!
  const _component_caseNoticeDay = _resolveComponent("caseNoticeDay")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "name" }, " 上传受伤者视频 ", -1)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_upload, {
                class: "upload-demo",
                action: _ctx.uploadAction,
                headers: { Authorization: _ctx.Authorization },
                "on-success": _ctx.fileSuccess,
                "on-error": _ctx.fileEerror,
                "show-file-list": true,
                "before-upload": _ctx.fileBeforeUpload,
                "file-list": _ctx.fileList,
                limit: 3,
                "on-remove": _ctx.fileRemove,
                accept: ".mp4"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", {
                    class: "btn-white-blue",
                    style: {"width":"130px","background":"#1989FA","color":"#fff"},
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBatchApplyEle && _ctx.onBatchApplyEle(...args)))
                  }, _cache[18] || (_cache[18] = [
                    _createElementVNode("i", { class: "el-icon el-icon-camera" }, null, -1),
                    _createTextVNode("选择视频文件 ")
                  ]))
                ]),
                _: 1
              }, 8, ["action", "headers", "on-success", "on-error", "before-upload", "file-list", "on-remove"]),
              _cache[19] || (_cache[19] = _createElementVNode("div", { class: "upload-introduce" }, " 视频时间10s-60s 视频大小不超50M ", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _cache[21] || (_cache[21] = _createElementVNode("div", { class: "name" }, [
              _createElementVNode("span", { class: "important" }, "*"),
              _createTextVNode(" 出险人 ")
            ], -1)),
            _createVNode(_component_el_select, {
              modelValue: _ctx.outDangerUserId,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.outDangerUserId) = $event)),
              filterable: "",
              placeholder: "选择出险人",
              class: "screen-i search-type",
              onChange: _ctx.onChangeSelect
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userList, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: index,
                    label: item.userName +
                            ' ' +
                            '(' +
                            item.idCardNo.slice(14, 18) +
                            ')' +
                            '          ' +
                            '投保时间' +
                            item.createTime
                            ,
                    value: item.id
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "onChange"])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[22] || (_cache[22] = _createElementVNode("div", { class: "name" }, [
              _createElementVNode("span", { class: "important" }, "*"),
              _createTextVNode(" 投保时间 ")
            ], -1)),
            _createVNode(_component_el_date_picker, {
              onChange: _ctx.tbonChangeDate,
              format: "YYYY-MM-DD",
              disabled: "",
              "value-format": "YYYY-MM-DD",
              style: {"width":"100%"},
              modelValue: _ctx.tbdate,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.tbdate) = $event)),
              type: "date",
              placeholder: "请选择投保时间"
            }, null, 8, ["onChange", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "name" }, [
              _createElementVNode("span", { class: "important" }, "*"),
              _createTextVNode(" 出险人电话 ")
            ], -1)),
            _createVNode(_component_el_input, {
              placeholder: "请输入出险人电话",
              modelValue: _ctx.outDangerPhone,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.outDangerPhone) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "name" }, [
              _createElementVNode("span", { class: "important" }, "*"),
              _createTextVNode(" 出险时间 ")
            ], -1)),
            _createVNode(_component_el_date_picker, {
              ref: "elDate",
              onChange: _ctx.onChangeDate,
              format: "YYYY-MM-DD",
              "value-format": "YYYY-MM-DD",
              style: {"width":"100%"},
              modelValue: _ctx.date,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.date) = $event)),
              type: "date",
              placeholder: "请选择出险时间",
              "disabled-date": _ctx.handleDisableDate
            }, null, 8, ["onChange", "modelValue", "disabled-date"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[27] || (_cache[27] = _createElementVNode("div", { class: "name" }, [
              _createElementVNode("span", { class: "important" }, "*"),
              _createTextVNode(" 事故类型 ")
            ], -1)),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_el_radio, {
                modelValue: _ctx.accidentType,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.accidentType) = $event)),
                label: "2",
                size: "large"
              }, {
                default: _withCtx(() => _cache[25] || (_cache[25] = [
                  _createTextVNode("工伤")
                ])),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_el_radio, {
                modelValue: _ctx.accidentType,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.accidentType) = $event)),
                label: "1",
                size: "large"
              }, {
                default: _withCtx(() => _cache[26] || (_cache[26] = [
                  _createTextVNode("意外")
                ])),
                _: 1
              }, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _cache[30] || (_cache[30] = _createElementVNode("div", { class: "name" }, [
              _createElementVNode("span", { class: "important" }, "*"),
              _createTextVNode(" 是否有骨折 ")
            ], -1)),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_el_radio, {
                modelValue: _ctx.hurtStatus,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.hurtStatus) = $event)),
                label: "1",
                size: "large"
              }, {
                default: _withCtx(() => _cache[28] || (_cache[28] = [
                  _createTextVNode("是")
                ])),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_el_radio, {
                modelValue: _ctx.hurtStatus,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.hurtStatus) = $event)),
                label: "2",
                size: "large"
              }, {
                default: _withCtx(() => _cache[29] || (_cache[29] = [
                  _createTextVNode("不是")
                ])),
                _: 1
              }, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[33] || (_cache[33] = _createElementVNode("div", { class: "name" }, [
              _createElementVNode("span", { class: "important" }, "*"),
              _createTextVNode(" 是否住院 ")
            ], -1)),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_el_radio, {
                modelValue: _ctx.hospitalStatus,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.hospitalStatus) = $event)),
                label: "1",
                size: "large"
              }, {
                default: _withCtx(() => _cache[31] || (_cache[31] = [
                  _createTextVNode("是")
                ])),
                _: 1
              }, 8, ["modelValue"]),
              _createVNode(_component_el_radio, {
                modelValue: _ctx.hospitalStatus,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.hospitalStatus) = $event)),
                label: "2",
                size: "large"
              }, {
                default: _withCtx(() => _cache[32] || (_cache[32] = [
                  _createTextVNode("不是")
                ])),
                _: 1
              }, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _cache[34] || (_cache[34] = _createElementVNode("div", { class: "name" }, [
              _createElementVNode("span", { class: "important" }, "*"),
              _createTextVNode(" 出险省市区 ")
            ], -1)),
            _createVNode(_component_el_cascader, {
              style: {"width":"100%"},
              placeholder: "请选择省市区",
              options: _ctx.areaList,
              modelValue: _ctx.area,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.area) = $event))
            }, null, 8, ["options", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _cache[35] || (_cache[35] = _createElementVNode("div", { class: "name" }, [
              _createElementVNode("span", { class: "important" }, "*"),
              _createTextVNode(" 出险地点 ")
            ], -1)),
            _createVNode(_component_el_input, {
              placeholder: "请输入出险地点，如xx公司xx车间",
              onBlur: _ctx.blurConfirmCode,
              modelValue: _ctx.place,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.place) = $event))
            }, null, 8, ["onBlur", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _cache[36] || (_cache[36] = _createElementVNode("div", { class: "name" }, [
              _createElementVNode("span", { class: "important" }, "*"),
              _createTextVNode(" 就诊医院 ")
            ], -1)),
            _createVNode(_component_el_input, {
              placeholder: "如：昆山市第一人民医院",
              modelValue: _ctx.visitHospital,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.visitHospital) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _cache[37] || (_cache[37] = _createElementVNode("div", { class: "name" }, [
              _createElementVNode("span", { class: "important" }, "*"),
              _createTextVNode(" 详细事故经过 ")
            ], -1)),
            _createVNode(_component_el_input, {
              type: "textarea",
              placeholder: "请输入详细事故经过",
              onBlur: _ctx.blurConfirmCode,
              modelValue: _ctx.after,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.after) = $event))
            }, null, 8, ["onBlur", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _cache[38] || (_cache[38] = _createElementVNode("div", { class: "name" }, [
              _createElementVNode("span", { class: "important" }, "*"),
              _createTextVNode(" 报案人电话 ")
            ], -1)),
            _createVNode(_component_el_input, {
              placeholder: "请输入报案人电话",
              onBlur: _ctx.blurConfirmCode,
              modelValue: _ctx.phone,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.phone) = $event))
            }, null, 8, ["onBlur", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _cache[39] || (_cache[39] = _createElementVNode("div", null, null, -1)),
            _createElementVNode("button", {
              class: "btn",
              onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.onModifyCode && _ctx.onModifyCode(...args)))
            }, "提交")
          ])
        ])
      ])
    ]),
    _createVNode(_component_caseNoticeDay, {
      show: _ctx.showNotice,
      "onUpdate:show": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.showNotice) = $event))
    }, null, 8, ["show"])
  ], 64))
}