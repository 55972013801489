import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"
import _imports_0 from '../assets/long-insure/play.png'


const _hoisted_1 = { class: "container block block-alone" }
const _hoisted_2 = { class: "head" }
const _hoisted_3 = { class: "head-title flex-jc-sb" }
const _hoisted_4 = { class: "head-box" }
const _hoisted_5 = { class: "head-box-left" }
const _hoisted_6 = { class: "head-box-i" }
const _hoisted_7 = { class: "head-box-i" }
const _hoisted_8 = { class: "head-box-i" }
const _hoisted_9 = { class: "head-box-i" }
const _hoisted_10 = { class: "head-box-i" }
const _hoisted_11 = { class: "head-box-i" }
const _hoisted_12 = { class: "head-box-i" }
const _hoisted_13 = {
  key: 0,
  class: "left-video-img",
  src: _imports_0,
  alt: ""
}
const _hoisted_14 = {
  key: 1,
  class: "el-icon el-icon-link left-video-img"
}
const _hoisted_15 = ["href"]
const _hoisted_16 = { class: "head-box-right" }
const _hoisted_17 = { class: "head-box-i" }
const _hoisted_18 = { class: "head-box-i" }
const _hoisted_19 = { class: "head-box-i" }
const _hoisted_20 = { class: "head-box-i" }
const _hoisted_21 = { class: "head-box-i" }
const _hoisted_22 = { class: "container block block-alone" }
const _hoisted_23 = { class: "process-box" }
const _hoisted_24 = { class: "process-timeline" }
const _hoisted_25 = { class: "timeline-item-i" }
const _hoisted_26 = { class: "i-title" }
const _hoisted_27 = { class: "i-time" }
const _hoisted_28 = { class: "timeline-item-remark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!
  const _component_el_timeline = _resolveComponent("el-timeline")!
  const _component_addMaterial = _resolveComponent("addMaterial")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "flex flex-ai-c" }, [
            _createElementVNode("div", { class: "title-decorate" }),
            _createElementVNode("span", null, "报案信息")
          ], -1)),
          (_ctx.caseInfo.reportStatus === 1)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "primary",
                size: "small",
                onClick: _ctx.showAddMaterial
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("导入")
                ])),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, "出险人姓名：" + _toDisplayString(_ctx.caseInfo.outDangerUserName), 1),
            _createElementVNode("div", _hoisted_7, "出险人身份证号：" + _toDisplayString(_ctx.caseInfo.idCardNo), 1),
            _createElementVNode("div", _hoisted_8, "出险人手机号：" + _toDisplayString(_ctx.caseInfo.outDangerPhone), 1),
            _createElementVNode("div", _hoisted_9, "出险时间：" + _toDisplayString(_ctx.caseInfo.outDangerDate), 1),
            _createElementVNode("div", _hoisted_10, "出险地点：" + _toDisplayString(_ctx.caseInfo.outDangerPlace), 1),
            _createElementVNode("div", _hoisted_11, "事故经过：" + _toDisplayString(_ctx.caseInfo.accidentDetails), 1),
            _createElementVNode("div", _hoisted_12, "被派遣单位：" + _toDisplayString(_ctx.caseInfo.serveCompanyName), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.caseInfo?.params?.fileUrl, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "left-video",
                key: index
              }, [
                (item.fileType === 'video')
                  ? (_openBlock(), _createElementBlock("img", _hoisted_13))
                  : (_openBlock(), _createElementBlock("i", _hoisted_14)),
                _createElementVNode("a", {
                  class: "left-video-a",
                  target: "_blank",
                  href: item.fileUrl
                }, "员工出险" + _toDisplayString(item.fileType === 'video' ? '视频' : '照片'), 9, _hoisted_15)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, "报案人手机号：" + _toDisplayString(_ctx.caseInfo.reportPhone), 1),
            _createElementVNode("div", _hoisted_18, "报案时间：" + _toDisplayString(_ctx.caseInfo.createTime), 1),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "head-box-i" }, "投保类型：日日保", -1)),
            _createElementVNode("div", _hoisted_19, "投保工种：" + _toDisplayString(_ctx.caseInfo.reportPlan), 1),
            _createElementVNode("div", _hoisted_20, "投保日期：" + _toDisplayString(_ctx.caseInfo.outDangerDate), 1),
            _createElementVNode("div", _hoisted_21, "投保方案：" + _toDisplayString(_ctx.caseInfo.insureCompanyName), 1)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", _hoisted_23, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "process-title" }, [
          _createElementVNode("div", { class: "title-decorate" }),
          _createTextVNode(" 案件进展 ")
        ], -1)),
        (_ctx.processList.length === 0)
          ? (_openBlock(), _createBlock(_component_el_empty, {
              key: 0,
              description: "暂无进展"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_24, [
          _createVNode(_component_el_timeline, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.processList, (activity, index) => {
                return (_openBlock(), _createBlock(_component_el_timeline_item, {
                  key: index,
                  icon: activity.icon,
                  type: activity.type,
                  color: activity.color,
                  size: activity.size,
                  hollow: activity.hollow
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, _toDisplayString(activity.reportSolveResult), 1),
                      _createElementVNode("div", _hoisted_27, _toDisplayString(activity.timestamp), 1)
                    ]),
                    _createElementVNode("div", _hoisted_28, _toDisplayString(activity.remark), 1)
                  ]),
                  _: 2
                }, 1032, ["icon", "type", "color", "size", "hollow"]))
              }), 128))
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createVNode(_component_addMaterial, {
      class: "reset",
      show: _ctx.showAdd,
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showAdd) = $event)),
      insureType: "day",
      "cur-item": _ctx.caseInfo
    }, null, 8, ["show", "cur-item"])
  ]))
}