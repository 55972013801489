import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "video-box" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "video-content mg-r-10" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "flex mg-b-12" }
const _hoisted_6 = { class: "fs-12 bold" }
const _hoisted_7 = { class: "flex mg-b-12" }
const _hoisted_8 = { class: "fs-12 bold" }
const _hoisted_9 = {
  key: 0,
  class: "flex"
}
const _hoisted_10 = { class: "fs-12 bold" }
const _hoisted_11 = {
  key: 1,
  class: "flex"
}
const _hoisted_12 = { class: "fs-12 bold" }
const _hoisted_13 = {
  key: 2,
  class: "flex flex-jc-end gap-10"
}

import { computed } from 'vue'
import VideoComponent from '@/components/VideoComponent/index.vue'
import { occupationStatusMap } from '@/utils/map'


export default /*@__PURE__*/_defineComponent({
  __name: 'ApplyItem',
  props: {
    itemData: {
        type: Object,
        default() {
            return {}
        }
    },
    showButton: {
        type: Boolean,
        default: false
    }
},
  emits: ['reject', 'confirm'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

const videoList = computed(() => {
    return props.itemData?.fileDetails?.map((i: any) => i.fileUrl)
})

const onWithdraw = () => {
    emits('confirm', props.itemData)
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(VideoComponent, { "video-list": videoList.value }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["status", [`status-${__props.itemData.status}`]])
            }, _toDisplayString(_unref(occupationStatusMap)[__props.itemData.status]), 3)
          ]),
          _: 1
        }, 8, ["video-list"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "fc-grey fs-12 label" }, "申请时间", -1)),
          _createElementVNode("div", _hoisted_6, _toDisplayString(__props.itemData.createTime), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fc-grey fs-12 label" }, "申请人", -1)),
          _createElementVNode("div", _hoisted_8, _toDisplayString(__props.itemData.createUserName), 1)
        ]),
        (__props.itemData.status === 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fc-grey fs-12 label" }, "申请工种", -1)),
              _createElementVNode("div", _hoisted_10, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.itemData.occupationList, (i, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(i.level) + "类 " + _toDisplayString(i.name), 1))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (__props.itemData.status === 2)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fc-grey fs-12 label" }, "驳回理由", -1)),
              _createElementVNode("div", _hoisted_12, _toDisplayString(__props.itemData.rejectRemark), 1)
            ]))
          : _createCommentVNode("", true),
        (__props.showButton)
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createElementVNode("div", {
                class: "button button-2 pointer",
                onClick: onWithdraw
              }, "撤销申请")
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})