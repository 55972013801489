import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "header block" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "search" }
const _hoisted_4 = { class: "container block block-alone" }
const _hoisted_5 = { class: "table" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "decorate-box" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "row" }
const _hoisted_12 = {
  class: "upload-box",
  style: {"width":"100%"}
}
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { style: {"width":"100%"} }
const _hoisted_15 = { class: "dialog-btn-box" }
const _hoisted_16 = { class: "alert-box" }
const _hoisted_17 = { class: "video-box" }
const _hoisted_18 = { class: "video-list" }
const _hoisted_19 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_batchPayroll = _resolveComponent("batchPayroll")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_alertBox = _resolveComponent("alertBox")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: "请输入单位名称",
            class: "input search-input",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
            onKeydown: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"]))
          }, null, 544), [
            [_vModelText, _ctx.searchText]
          ]),
          _createElementVNode("button", {
            class: "btn",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
          }, "搜索"),
          _createElementVNode("button", {
            class: "btn-white",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args)))
          }, "重置")
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "btn",
            style: {"width":"128px"},
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.centerDialogVisible=true))
          }, "添加被派遣单位")
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_tabs, { "model-value": "detail" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_table, {
              data: _ctx.tableData,
              style: {"width":"100%"},
              "header-row-style": { color: '#202536' },
              "row-style": { color: '#5E617D' },
              onSelectionChange: _ctx.handleSelectionChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  prop: "serveCompanyName",
                  label: "被派遣单位",
                  width: "200px"
                }),
                _createVNode(_component_el_table_column, { label: "可用工种" }, {
                  default: _withCtx((scope) => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.occupationList, (item, index) => {
                      return (_openBlock(), _createElementBlock("p", { key: index }, _toDisplayString(item.name) + " " + _toDisplayString('(' + item.level + '类' + ')'), 1))
                    }), 128))
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { label: "视频" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", null, [
                      (scope.row.fileDetailList && scope.row.fileDetailList[0] !== '')
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 0,
                            style: {"color":"#1989FA"},
                            class: "point",
                            onClick: ($event: any) => (_ctx.onFileDetail(scope.row.fileDetailList))
                          }, "点击查看", 8, _hoisted_6))
                        : _createCommentVNode("", true),
                      _createElementVNode("p", _hoisted_7, _toDisplayString(scope.row.fileDetailList && scope.row.fileDetailList[0] === '' ? '暂无视频' : ''), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  width: "240px",
                  prop: "createTime",
                  label: "添加时间"
                }),
                _createVNode(_component_el_table_column, { label: "状态" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", {
                        class: "decorate",
                        style: _normalizeStyle({background:scope.row.status == 0 ? '#FFC53D' : scope.row.status == 1 ? '#00D679' : '#FF7E68'})
                      }, null, 4),
                      _createElementVNode("p", null, _toDisplayString(scope.row.status == 0 ? '待审核' : scope.row.status == 1 ? '已通过' : scope.row.status == 2 ? '驳回' : scope.row.status == 3 ? '拒保' : ''), 1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { label: "操作" }, {
                  default: _withCtx((scope) => [
                    (!scope.row.status)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 0,
                          style: {"color":"#1989FA"},
                          class: "point",
                          onClick: ($event: any) => (
                                          _ctx.cancelDispatchExamine(scope.row.id)
                                      )
                        }, " 取消 ", 8, _hoisted_9))
                      : _createCommentVNode("", true),
                    (scope.row.status)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 1,
                          style: {"color":"#FF7E68"},
                          class: "point",
                          onClick: ($event: any) => (
                                          _ctx.deleteDispatchExamine(scope.row.id)
                                      )
                        }, " 删除 ", 8, _hoisted_10))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data", "onSelectionChange"]),
            _createVNode(_component_el_pagination, {
              class: "pagination",
              currentPage: _ctx.pageNum,
              "onUpdate:currentPage": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pageNum) = $event)),
              "page-size": _ctx.pageSize,
              "onUpdate:pageSize": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pageSize) = $event)),
              "page-sizes": [10, 30, 50, 100],
              layout: "total, sizes, prev, pager, next, jumper",
              total: _ctx.total,
              onSizeChange: _ctx.handleSizeChange,
              onCurrentChange: _ctx.getPayrollList
            }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
          ])
        ]),
        _: 1
      }),
      (_ctx.isBatchPay)
        ? (_openBlock(), _createBlock(_component_batchPayroll, {
            key: 0,
            onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.isBatchPay = false)),
            onComplete: _ctx.getPayrollList,
            isType: "payroll"
          }, null, 8, ["onComplete"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.centerDialogVisible,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.centerDialogVisible) = $event)),
      title: "新增被派遣单位",
      width: "642px",
      "destroy-on-close": "",
      center: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_11, [
          _cache[16] || (_cache[16] = _createElementVNode("div", { class: "name" }, [
            _createElementVNode("span", {
              class: "important",
              style: {"opacity":"-0.9"}
            }, "*"),
            _createTextVNode(" 上传视频 ")
          ], -1)),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_el_upload, {
              class: "upload-demo",
              action: _ctx.uploadAction,
              headers: {Authorization: _ctx.Authorization},
              "on-success": _ctx.fileSuccess,
              "on-error": _ctx.fileEerror,
              "show-file-list": false,
              "before-upload": _ctx.fileBeforeUpload,
              accept: "image/jpeg,image/image/png,video/*"
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [
                _createElementVNode("button", {
                  class: "btn-white-blue",
                  style: {"width":"130px","background":"#1989FA","color":"#fff"}
                }, [
                  _createElementVNode("i", { class: "el-icon el-icon-camera" }),
                  _createTextVNode("选择视频文件 ")
                ], -1)
              ])),
              _: 1
            }, 8, ["action", "headers", "on-success", "on-error", "before-upload"]),
            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "upload-introduce" }, " 视频时间10s-60s 视频大小不超50M ", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _cache[17] || (_cache[17] = _createElementVNode("div", { class: "name" }, [
            _createElementVNode("span", { class: "important" }, "*"),
            _createTextVNode(" 被派遣单位 ")
          ], -1)),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_el_select, {
              modelValue: _ctx.companyId,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.companyId) = $event)),
              filterable: "",
              placeholder: "请选择派遣单位"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.companyList, (item) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: item.id,
                    label: item.serveCompanyName,
                    value: item.id
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("button", {
            class: "btn-white",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.centerDialogVisible=false)),
            style: {"marginRight":"20px"}
          }, "取消"),
          _createElementVNode("button", {
            class: "btn",
            onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.addDispatchUnit && _ctx.addDispatchUnit(...args))),
            style: {"marginLength":"20px"}
          }, "提交审核")
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.videoList.length)
      ? (_openBlock(), _createBlock(_component_alertBox, {
          key: 0,
          "data-title": (_ctx.videoIndex + 1) + '/' + _ctx.videoList.length,
          isCloseType: true,
          onClose: _ctx.onVideoCallback
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", null, [
                  _createElementVNode("i", {
                    style: {"font-size":"50px"},
                    class: "el-icon el-icon-arrow-left",
                    onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.onVideoUpper && _ctx.onVideoUpper(...args)))
                  })
                ]),
                _createElementVNode("div", _hoisted_18, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videoList, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      (_ctx.videoIndex === index)
                        ? (_openBlock(), _createElementBlock("video", {
                            key: 0,
                            class: "video",
                            src: item,
                            preload: "",
                            controls: ""
                          }, null, 8, _hoisted_19))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("i", {
                    style: {"font-size":"50px"},
                    class: "el-icon el-icon-arrow-right",
                    onClick: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.onVideoNext && _ctx.onVideoNext(...args)))
                  })
                ])
              ])
            ])
          ]),
          _: 1
        }, 8, ["data-title", "onClose"]))
      : _createCommentVNode("", true)
  ]))
}