import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, isRef as _isRef } from "vue"
import _imports_0 from '../../../assets/img/long-insure-dispatch/bg-1.png'
import _imports_1 from '../../../assets/img/long-insure-dispatch/icon-hint.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "head-box" }
const _hoisted_3 = { class: "flex flex-jc-sb" }
const _hoisted_4 = { class: "mg-t-28" }
const _hoisted_5 = { class: "fs-20 bold" }
const _hoisted_6 = {
  key: 0,
  class: "hint-box flex flex-ai-c"
}
const _hoisted_7 = { class: "fc-grey fs-14" }
const _hoisted_8 = {
  key: 0,
  class: "item-box mg-t-8"
}
const _hoisted_9 = { class: "video-container" }
const _hoisted_10 = { class: "footer-container" }
const _hoisted_11 = { class: "footer-box flex flex-jc-end" }

import { ref, watch } from 'vue'
import { cloneDeep } from 'lodash'
import { ElMessageBox, ElMessage } from 'element-plus'
import {
    deleteDispatchExamine,
    cancelDispatchExamine,
    getOccupationDetailById,
    getOccupationList,
    deleteOccupation
} from '@/api/long-insure'
import OccupationCard from '../long-insure-dispatch/OccupationCard.vue'
import ApplyItem from '../long-insure-dispatch/ApplyItem.vue'
import DialogAddCompany from '../long-insure-dispatch/DialogAddCompany.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'LongInsureDispatchDrawer',
  props: {
    show: {
        type: Boolean,
        default: false
    },
    id: {
        type: Number
    }
},
  emits: ['update:show', 'success'],
  setup(__props, { emit: __emit }) {

const props = __props

const emits = __emit

let showDrawer = ref(false)
let showAddOccupation = ref(false)
let itemDetail = ref({}) as any
let detailData = ref({})

let showAddCompany = ref(false)

let recordList = ref([]) as any

/**
 * 撤销
 * @param item
 */
const onHandleConfirm = (item: any) => {
    if (itemDetail.value.status === 0) {
        handleWithDrawCompany()
    } else {
        handleWithDrawOccupation()
    }
}
/**
 * 撤销审核单位
 */
const handleWithDrawCompany = async () => {
    ElMessageBox.confirm(
        '确定撤销该条记录？',
        '撤销确认',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }
    )
        .then(async () => {
            const result = await cancelDispatchExamine({ id: itemDetail.value.id })
            emits('success')
            ElMessage.success('撤销成功！')
            onCloseDrawer()
        })
}
/**
 * 撤销工种
 */
const handleWithDrawOccupation = async () => {
    ElMessageBox.confirm(
        '确定撤销该条记录？',
        '撤销确认',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }
    )
        .then(async () => {
            const result = await deleteOccupation(recordList.value[0].id)
            ElMessage.success('撤销成功！')
            emits('success')
            await getData()
        })
}
/**
 * 点击申请新工种
 */
const onApply = () => {
    showAddCompany.value = true
}

const onCloseDrawer = () => {
    showDrawer.value = false
}

/**
 * 删除派遣单位
 */
const onDelete = () => {
    ElMessageBox.confirm(
        '删除后该企业将无法投保，已申请是工种将被清除，确定删除？',
        '删除投保企业确认',
        {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }
    )
        .then(async () => {
            const result = await deleteDispatchExamine({ id: props.id })
            ElMessage.success('删除成功！')
            emits('success')
            onCloseDrawer()
        })
}

const onRefresh = async () => {
    emits('success')
    await getData()
    // onCloseDrawer()
}

const getData = async () => {
    const res = await getOccupationDetailById(props.id)
    itemDetail.value = res
    if (res.status === 0) {
        recordList.value = [
            {
                createTime: res.createTime,
                createUserName: res.createUserName,
                fileDetails: res.fileDetails,
                status: res.status
            }
        ]
    } else {
        await getOccupationData()
    }
}

const getOccupationData = async () => {
    const res = await getOccupationList({
        periodServeCompanyId: props.id,
        isDelete: 0
    })
    recordList.value = res
}

watch(() => props.show, (newval) => {
    showDrawer.value = newval
})

watch(showDrawer, (newval) => {
    emits('update:show', newval)
})

watch(() => props.id, async (newval) => {
    if (newval) {
        await getData()
    }
    // detailData.value = cloneDeep(newval)
})


return (_ctx: any,_cache: any) => {
  const _component_Close = _resolveComponent("Close")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_drawer, {
      "custom-class": "reset",
      modelValue: _unref(showDrawer),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(showDrawer) ? (showDrawer).value = $event : showDrawer = $event)),
      size: "462px"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(itemDetail).serveCompanyName), 1)
              ]),
              _cache[2] || (_cache[2] = _createElementVNode("img", {
                class: "icon-bg1",
                src: _imports_0,
                alt: ""
              }, null, -1))
            ]),
            (_unref(itemDetail).remark)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _cache[3] || (_cache[3] = _createElementVNode("img", {
                    class: "icon-hint",
                    src: _imports_1,
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_7, "特别约定：" + _toDisplayString(_unref(itemDetail).remark), 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_el_icon, {
              class: "icon-close pointer",
              onClick: onCloseDrawer
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Close)
              ]),
              _: 1
            })
          ]),
          (_unref(itemDetail).occupationList?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(OccupationCard, {
                  "list-data": _unref(itemDetail).occupationList || [],
                  "show-video": "",
                  "video-list": _unref(itemDetail).fileDetailList
                }, null, 8, ["list-data", "video-list"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_9, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "fs-16 bold mg-t-28 mg-b-10" }, "申请列表", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(recordList), (i, index) => {
              return (_openBlock(), _createBlock(ApplyItem, {
                key: index,
                class: "mg-b-10",
                itemData: i,
                "show-button": index === 0 && _unref(recordList)[0].status === 0,
                onConfirm: onHandleConfirm
              }, null, 8, ["itemData", "show-button"]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_el_button, {
                type: "danger",
                onClick: onDelete,
                disabled: _unref(itemDetail).status === 3
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("删除企业")
                ])),
                _: 1
              }, 8, ["disabled"]),
              _createVNode(_component_el_button, {
                type: "primary",
                disabled: _unref(itemDetail).status === 3  || _unref(itemDetail).status === 0 || _unref(itemDetail).status === 2 || (_unref(itemDetail).status === 1 && _unref(itemDetail).occupationRecord
            ?.status === 0),
                onClick: onApply
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("申请新工种")
                ])),
                _: 1
              }, 8, ["disabled"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(DialogAddCompany, {
      show: _unref(showAddCompany),
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => (_isRef(showAddCompany) ? (showAddCompany).value = $event : showAddCompany = $event)),
      "cur-company-id": _unref(itemDetail).serveCompanyId,
      id: _unref(itemDetail).id,
      "add-type": "apply",
      onSuccess: onRefresh
    }, null, 8, ["show", "cur-company-id", "id"])
  ]))
}
}

})