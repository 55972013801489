import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, unref as _unref, isRef as _isRef, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { ref, computed } from 'vue'
import FullVideo from './FullVideo.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    videoUrl: {
        type: String
    },
    videoList: {
        type: Array
    },
    width: {
        type: Number,
        default: 90
    },
    height: {
        type: Number,
        default: 68
    }
},
  setup(__props) {

const props = __props
let showVideo = ref(false)

const videoCover = computed(() => {
    return props.videoUrl || props.videoList?.[0]
})

const onShowVideo = () => {
    showVideo.value = true
}

return (_ctx: any,_cache: any) => {
  const _component_VideoPlay = _resolveComponent("VideoPlay")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "video-wraper",
      style: _normalizeStyle({width: __props.width + 'px', height: __props.height + 'px'}),
      onClick: onShowVideo
    }, [
      _createElementVNode("video", {
        src: videoCover.value,
        class: "small-video"
      }, null, 8, _hoisted_1),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "shadow pointer" }, null, -1)),
      _createVNode(_component_el_icon, { class: "icon-play pointer" }, {
        default: _withCtx(() => [
          _createVNode(_component_VideoPlay)
        ]),
        _: 1
      }),
      _renderSlot(_ctx.$slots, "default")
    ], 4),
    _createVNode(FullVideo, {
      show: _unref(showVideo),
      "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => (_isRef(showVideo) ? (showVideo).value = $event : showVideo = $event)),
      "video-url": __props.videoUrl,
      "video-list": __props.videoList
    }, null, 8, ["show", "video-url", "video-list"])
  ], 64))
}
}

})