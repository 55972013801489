import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "full-video-container"
}
const _hoisted_2 = { class: "full-video-box" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 2,
  class: "controls-box"
}

import { ref, watch } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
/**
 * 单个的直接传videoUrl 多个的传videoList 两个传一个就行
 * videoList 格式为 ['xxxxxx.mp4']
 */

export default /*@__PURE__*/_defineComponent({
  __name: 'FullVideo',
  props: {
    show: {
        type: Boolean,
        default: false
    },
    videoUrl: {
        type: String
    },
    videoList: {
        type: Array
    },
    controls: {
        type: Boolean,
        default: false
    }
},
  emits: ['update:show'],
  setup(__props, { emit: __emit }) {

const props = __props
const emits = __emit

let showDialog = ref(false)

let videoRef = ref<HTMLVideoElement | null>(null)

let videoLiseRef = ref({}) as any

let cur = ref(0)

const setItemRefs = (el: HTMLElement, index: number) => {
    if (el) {
        videoLiseRef.value[`video${index}`] = el
    }
}
/**
 * 点击关闭按钮，关闭后将正在播放的视频暂停掉
 */
const onClose = () => {
    if (props.videoUrl) {
        (videoRef.value as HTMLVideoElement).pause()
    } else {
        for (const key in videoLiseRef.value) {
            videoLiseRef.value[key].pause()
        }
    }
    showDialog.value = false
}
/**
 * 点击删除按钮
 */
const onRemove = () => {
    ElMessageBox.confirm(
        '确认删除该视频吗？',
        '提示',
        {
            type: 'warning'
        }
    )
        .then(() => {
            if (props.videoUrl) {

            } else {

            }
        })
}
/**
 * 点击下载按钮
 */
 const onDownload = () => {
    if (props.videoUrl) {
        handleDownload(props.videoUrl)
    } else {
        handleDownload((props.videoList as any)[cur.value])
    }
}

/**
 * 下载视频
 * @param url
 */
const handleDownload = (url: string) => {
    fetch(url).then(res => {
        return res.blob()
    }).then((blob: Blob) => {
        const a = document.createElement('a')
        const objUrl = window.URL.createObjectURL(blob)
        a.href = objUrl
        a.download = '视频'
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(objUrl)
        document.body.removeChild(a)
    })
}

const onPlay = () => {
    if (props.videoUrl) {
        (videoRef.value as HTMLVideoElement).play()
    } else {
        for (const key in videoLiseRef.value) {
            videoLiseRef.value[key].pause()
        }
        videoLiseRef.value[`video${cur.value}`].play()
    }
}

const onChange = (ev: number) => {
    cur.value = ev
}

watch(() => props.show, (newval) => {
    showDialog.value = newval
})

watch(showDialog, (newval) => {
    emits('update:show', newval)
})


return (_ctx: any,_cache: any) => {
  const _component_el_carousel_item = _resolveComponent("el-carousel-item")!
  const _component_el_carousel = _resolveComponent("el-carousel")!
  const _component_Close = _resolveComponent("Close")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_unref(showDialog))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (__props.videoUrl)
            ? (_openBlock(), _createElementBlock("video", {
                key: 0,
                ref_key: "videoRef",
                ref: videoRef,
                class: "video-item",
                controls: "",
                src: __props.videoUrl
              }, null, 8, _hoisted_3))
            : (_openBlock(), _createBlock(_component_el_carousel, {
                key: 1,
                height: "496px",
                autoplay: false,
                onChange: onChange
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.videoList, (item, index) => {
                    return (_openBlock(), _createBlock(_component_el_carousel_item, { key: index }, {
                      default: _withCtx(() => [
                        _createElementVNode("video", {
                          ref_for: true,
                          ref: (el) => setItemRefs(el, index),
                          class: "video-item",
                          controls: "",
                          src: item
                        }, null, 8, _hoisted_4)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })),
          _createVNode(_component_el_icon, {
            class: "icon-close pointer",
            onClick: onClose
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Close)
            ]),
            _: 1
          }),
          (__props.controls)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", {
                  class: "icon-delete mg-b-25 pointer",
                  onClick: onRemove
                }),
                _createElementVNode("div", {
                  class: "icon-download mg-b-25 pointer",
                  onClick: onDownload
                }),
                _createElementVNode("div", {
                  class: "icon-play pointer",
                  onClick: onPlay
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})