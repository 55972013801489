import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "header block" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "search align-items" }
const _hoisted_4 = { class: "container block block-alone" }
const _hoisted_5 = { class: "table" }
const _hoisted_6 = { class: "point" }
const _hoisted_7 = { class: "flex gap-16" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_addMaterial = _resolveComponent("addMaterial")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _cache[10] || (_cache[10] = _createElementVNode("text", null, "理赔状态：", -1)),
            _createVNode(_component_el_select, {
              modelValue: _ctx.stateCode,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stateCode) = $event)),
              class: "screen-i search-type"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_option, {
                  label: "待处理",
                  value: "0"
                }),
                _createVNode(_component_el_option, {
                  label: "处理中",
                  value: "1"
                }),
                _createVNode(_component_el_option, {
                  label: "已结案",
                  value: "2"
                }),
                _createVNode(_component_el_option, {
                  label: "已驳回",
                  value: "3"
                }),
                _createVNode(_component_el_option, {
                  label: "已撤销",
                  value: "4"
                }),
                _createVNode(_component_el_option, {
                  label: "处理中",
                  value: "5"
                }),
                _createVNode(_component_el_option, {
                  label: "已结案",
                  value: "6"
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createElementVNode("div", null, [
            _cache[11] || (_cache[11] = _createElementVNode("text", null, "资料状态：", -1)),
            _createVNode(_component_el_select, {
              modelValue: _ctx.uploadStatus,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.uploadStatus) = $event)),
              class: "screen-i search-type"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_option, {
                  label: "全部",
                  value: ""
                }),
                _createVNode(_component_el_option, {
                  label: "已上传",
                  value: "1"
                }),
                _createVNode(_component_el_option, {
                  label: "未上传",
                  value: "0"
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createElementVNode("div", null, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              placeholder: "请输入出险姓名或身份证",
              class: "input search-input",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchText) = $event)),
              onKeydown: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)), ["enter"]))
            }, null, 544), [
              [_vModelText, _ctx.searchText]
            ]),
            _createElementVNode("button", {
              class: "btn",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args)))
            }, "搜索"),
            _createElementVNode("button", {
              class: "btn-white",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onReset && _ctx.onReset(...args)))
            }, "重置")
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("button", {
            class: "btn",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$router.push({
                        path: '/insure-report-add'
                    })))
          }, "新增理赔")
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_tabs, { "model-value": "detail" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_el_table, {
              data: _ctx.tableData,
              style: {"width":"100%"},
              "header-row-style": { color: '#202536' },
              "row-style": { color: '#5E617D' },
              onSelectionChange: _ctx.handleSelectionChange
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  prop: "outDangerUserName",
                  label: "出险人"
                }),
                _createVNode(_component_el_table_column, {
                  label: "身份证",
                  prop: "idCardNo"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "reportPlan",
                  label: "方案类型"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "outDangerDate",
                  label: "出险时间"
                }),
                _createVNode(_component_el_table_column, {
                  prop: "outDangerPlace",
                  label: "出险地点"
                }),
                _createVNode(_component_el_table_column, { label: "状态" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("p", _hoisted_6, _toDisplayString(scope.row.reportStatus === 1 || scope.row.reportStatus === 5 ? '处理中' :
                                        scope.row.reportStatus === 2 || scope.row.reportStatus === 6 ? '已结案' :
                                            scope.row.reportStatus === 3 ? '已驳回' : scope.row.reportStatus === 4 ? '已撤销' : '待处理'), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, { label: "操作" }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("p", {
                        style: {"color":"#1989FA"},
                        class: "point",
                        onClick: ($event: any) => (
                                        _ctx.$router.push({
                                            path: '/insure-report-detail/' + scope.row.id
                                        })
                                        )
                      }, " 查看详情 ", 8, _hoisted_8),
                      (scope.row.reportStatus === 1 && _ctx.canShow(scope.row.insureCompanyType))
                        ? (_openBlock(), _createElementBlock("p", {
                            key: 0,
                            style: {"color":"#1989FA"},
                            class: "point",
                            onClick: ($event: any) => (_ctx.showAddMaterial(scope.row))
                          }, " 材料 ", 8, _hoisted_9))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data", "onSelectionChange"]),
            _createVNode(_component_el_pagination, {
              class: "pagination",
              currentPage: _ctx.pageNum,
              "onUpdate:currentPage": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pageNum) = $event)),
              "page-size": _ctx.pageSize,
              "onUpdate:pageSize": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.pageSize) = $event)),
              "page-sizes": [10, 30, 50, 100],
              layout: "total, sizes, prev, pager, next, jumper",
              total: _ctx.total,
              onSizeChange: _ctx.handleSizeChange,
              onCurrentChange: _ctx.getPayrollList
            }, null, 8, ["currentPage", "page-size", "total", "onSizeChange", "onCurrentChange"])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_addMaterial, {
      class: "reset",
      show: _ctx.showAdd,
      "onUpdate:show": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.showAdd) = $event)),
      insureType: "day",
      "cur-item": _ctx.curItem
    }, null, 8, ["show", "cur-item"])
  ]))
}